import React, { Component } from "react";
import { Row, Col, Container } from "bootstrap-4-react";
import Navmenu from "../components/Navmenu";
import Footer from "../components/Footer";
import "../style/Glimpses.scss";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Appbar from "../components/Appbar";
import { List } from "bootstrap-4-react";
import { Media, BImg, BH5, BH6 } from "bootstrap-4-react";
import axios from "axios";
import { apiURL, getID, isLogin } from "../utils";
import Moment from "moment";
import { Link, Redirect } from "react-router-dom";
import Sign from "../login/Sign";
import Meta from "../meta/Meta";
import HashLoader from "react-spinners/HashLoader";

const styles = (theme) => ({});

class Article extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      videoUrl: "",
      open: false,
      isOpen: false,
      articleCategory: [],
      articleData: [],
      categoryID: null,
      noData: "",
      selectedTab: 0,
      showComponent: false,
      id: "",
      title: "",
      loading: true,
    };
    this.openModal = this.openModal.bind(this);
    this.articleCategory = this.articleCategory.bind(this);
    this.articleListData = this.articleListData.bind(this);
  }

  clickCategory = (e, id) => {
    console.log(id);
    this.setState(
      {
        categoryID: id,
        selectedTab: id,
      },
      () => {
        this.articleListData();
      }
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    window.addEventListener("scroll", this.signNav);
    this.articleCategory();
    this.articleListData();
  }

  articleCategory = () => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("article_category/get_all_article_category"), {
        headers: headers,
      })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({ articleCategory: resultData.result });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  articleListData = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      id: "",
      category_id: this.state.categoryID,
    };
    axios
      .post(apiURL("article/get_all_article"), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              articleData: resultData.result,
              noData: "",
              loading: false,
            });
          } else {
            this.setState({
              noData: "Coming Soon",
              articleData: [],
              loading: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClickOpen = (e, videoUrl) => {
    this.setState({ open: true, videoUrl: videoUrl });
  };
  handleClose = () => {
    this.setState({ open: false, videoUrl: "" });
  };

  openModal() {
    this.setState({ isOpen: false });
  }

  checkJoinNow = () => {
    this.setState({ showComponent: false });
    //this.props.history.push('article/'+this.state.id+'/'+this.state.title.replace(/ /g, '-').toLowerCase());
  };

  redirectArticle = (e, id, title) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.props.history.push(
        "/article/" + id + "/" + title.replace(/ /g, "-").toLowerCase()
      );
    }
    this.setState({ id: id, title: title });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />
        {this.state.showComponent ? (
          <Sign
            ref={this._sign}
            pageProp={this.props}
            checkJoinNow={(e) => this.checkJoinNow(e)}
          />
        ) : (
          <div>
            <Appbar />
            <div className="sticky-top sign-light">
              <Navmenu />
            </div>
            <section className="BannerBox videoBanner">
              <div className="sixteen-nine">
                <div className="content text-center text-white d-none">
                  <h2>Articles </h2>
                  <p>Update</p>
                </div>
              </div>
            </section>

            <section>
              <Container>
                <Row className="row aricleBox">
                  <Col sm="12" className="categoryMenu1">
                    <List inline>
                      <List.Item
                        inline
                        onClick={(e) => this.clickCategory(e, null)}
                        className={this.state.selectedTab === 0 ? "active" : ""}
                      >
                        All
                      </List.Item>
                      {/* {this.state.articleCategory.reverse().map((cat, i) => { */}
                      {this.state.articleCategory.map((cat, i) => {
                        return (
                          <List.Item
                            key={i}
                            inline
                            onClick={(e) => this.clickCategory(e, cat.fldi_id)}
                            className={
                              this.state.selectedTab === cat.fldi_id
                                ? "active"
                                : ""
                            }
                          >
                            {cat.fldv_category}
                          </List.Item>
                        );
                      })}
                    </List>
                  </Col>

                  {/* <Col sm="12" style={{display:"flex", justifyContent:"center"}}>    
            <HashLoader size={150} color={"#123abc"} loading={this.state.loading} css="" />
        </Col> */}

                  {this.state.articleData.map((arrArticle, index) => {
                    return (
                      <Col
                        key={index}
                        lg="6"
                        md="12"
                        sm="12"
                        className="mt-4  mainBox "
                      >
                        {/* <Link target="blank" to={"/article/"+arrArticle.fldi_id+"/"+arrArticle.fldv_title.replace(/ /g, '-').toLowerCase()}> */}
                        <Link>
                          <Media
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              this.redirectArticle(
                                e,
                                arrArticle.fldi_id,
                                arrArticle.fldv_title
                              )
                            }
                            className="h-100"
                          >
                            {/* <Media style={{cursor:"pointer"}} className="h-100"> */}
                            <Media className="h-100">
                              <div className="imgWidth mr-3">
                                <BImg
                                  src={arrArticle.fldv_image_url}
                                  alt={arrArticle.fldv_title}
                                  className="img-fluid"
                                />
                              </div>
                              <Media.Body>
                                <BH5 mt="0">{arrArticle.fldv_title}</BH5>
                                <BH6>
                                  {" "}
                                  {Moment(arrArticle.fldv_changed_date).format(
                                    "D MMMM, YYYY"
                                  )}
                                </BH6>
                              </Media.Body>
                            </Media>
                          </Media>
                        </Link>
                        <Divider />
                      </Col>
                    );
                  })}

                  <Col sm="12" className="mt-4 text-center">
                    <h1>{this.state.noData}</h1>
                  </Col>
                </Row>
              </Container>
            </section>

            <Footer />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Article);
