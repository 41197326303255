import React, { Component } from "react";
import ReactDOM from "react-dom";
import Navmenu from "../components/Navmenu";
import Appbar from "../components/Appbar";
import { Container, Row, Col, List } from "bootstrap-4-react";
import Footer from "../components/Footer";
// import Button from '@material-ui/core/Button';

import { withStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@material-ui/core/styles';
import ContactForm from "../contact/ContactForm.jsx";
import "../style/Contact.scss";
import Meta from "../meta/Meta.jsx";
import { Helmet } from "react-helmet";
import {
  isLogin,
  getContact,
  getEmail,
  getName,
  getSubjects,
  getID,
  apiURL,
} from "../utils/index.js";
import axios from "axios";
const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
});
const orgSubjects = getSubjects()
  ? getSubjects()
      .split(",")
      .map((sub) => sub.trim())
  : [];

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getUserData();
  }
  getUserData = () => {
    const body = {
      fldi_id: getID(),
    };
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    axios
      .post(apiURL("teacher/get_profile"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const firstname =
            resultData.result.fldv_first_name !== ""
              ? resultData.result.fldv_first_name
              : resultData.result.fldv_name;
          const lastname =
            resultData.result.fldv_last_name !== ""
              ? resultData.result.fldv_last_name
              : resultData.result.fldv_surname;

          const contact = resultData.result.fldv_contact;
          const email = resultData.result.fldv_email;

          const userData = {
            firstname,
            lastname,

            contact,
            email,
          };
          this.setState({
            userData: userData,
          });
        } else {
          this.setState({
            noData: "Coming Soon",
            loading: false,
            scrolling: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { classes, location } = this.props;
    const { userData } = this.state;
    // Access the state passed from NavLink or set a default value
    const topic = location.state?.topic || "";

    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={16} forPage={"main_page"} />
        <Helmet>
          <title>Contact Us</title>
          <meta charSet="utf-8" />
          <link rel="icon" href="/navdisha-favicon.png" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="theme-color" content="#000000" />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
          <meta name="robots" content="Contactus" />
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content="Contactus" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <Appbar />
        {/* <div className="NavBar   pb-0 sticky-top ">
      <Navmenu />
   </div> */}
        <div className="sticky-top sign-light">
          <Navmenu />
        </div>
        <section className="BannerBox discussionBanner contactBanner">
          <div className="sixteen-nine">
            <div className="content text-center text-white d-none">
              <h2>Contact Us</h2>
              <p>Lorem Ipsum</p>
            </div>
          </div>
        </section>
        <section className="commentBox contactBox">
          <Container>
            <Row>
              <Col sm="12" className="text-center">
                <h3 style={{ color: "black" }}>
                  Get In <span style={{ color: "#064e89" }}>Touch</span>
                </h3>
                <div className="info">
                  <p>
                    We're here to help and support your journey in education.
                    Whether you have questions, need assistance, or want to
                    provide feedback, feel free to reach out to us. Connect with
                    us through the form below. We're looking forward to hearing
                    from you!
                  </p>
                </div>
                {/* <h2>Please feel free to contact us.</h2> */}
              </Col>
              <Col sm="6">
                <ContactForm
                  userData={userData}
                  topic={topic}
                  history={this.props.history}
                />
              </Col>
              <Col sm="6" className="d-flex">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.08975178522!2d72.8342693149048!3d19.015766358804544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cef28535c3d9%3A0xc9046dc6bf92426e!2sNavneet%20Education%20Limited!5e0!3m2!1sen!2sin!4v1598254541734!5m2!1sen!2sin"
                  frameborder="0"
                  className="mapBox"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </Col>
              {/* <Col sm="9" className="mt-sm-5 mt-4 text-center mx-auto">
            <h3 className="supportBox">Our Support Guys or <br></br> Make Appointment With Our Consultant</h3>
            <p>Please contact us using the information below. <br></br>
               For additional information on our management consulting services, please visit the appropriate page on our site.
            </p>
            </Col> */}
            </Row>

            <section className="addressBox">
              <Row className="row">
                <Col md="6" sm="6" className="col">
                  <div className="textBox">
                    <p>
                      <h3>Mumbai</h3>
                      Navneet Bhavan, Bhavani Shankar Road, Dadar (W). <br />{" "}
                      Mumbai - 400 028. India.
                      <br />
                      +91 22 66626565
                      <br />
                      +91 22 66626470
                    </p>

                    {/* <List unstyled>
                     <List.Item>CIN: L22200MH1984PLC034055</List.Item>
                  </List> */}
                  </div>
                </Col>
                <Col md="6" sm="6" className="col">
                  <div>
                    {/* <h3>Ahmedabad</h3> */}
                    <div className="textBox">
                      <p>
                        <h3>Ahmedabad</h3>
                        Navneet House, Gurukul Road, Memnager, <br /> Ahmedabad
                        380052. India. <br /> +91 22 66626565 <br /> +91 22
                        66626470
                      </p>
                    </div>
                    {/* <List unstyled>
                     <List.Item>Mail:inquiry@navneet.com</List.Item>
                  </List> */}
                  </div>
                </Col>
              </Row>
            </section>
          </Container>
        </section>

        <div className="customFooter">
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(Contact);
