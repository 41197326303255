import React, { Component } from "react";
import { List } from "bootstrap-4-react";
import Navmenu from "../components/Navmenu";
import Appbar from "../components/Appbar";
import "../style/Webinar.scss";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import {
  ArrowBack,
  LocationOn,
  Bookmark,
  BookmarkBorder,
} from "@material-ui/icons";
import {
  apiURL,
  isLogin,
  getName,
  getEmail,
  getContact,
  getID,
  getLastName,
  getRole,
  getBoard,
  getGrades,
  getSubjects,
  getCity,
  getPincode,
  getSchCollName,
} from "../utils";
import axios from "axios";
import Moment from "moment";
import SanitizedHTML from "react-sanitized-html";
import Sign from "../login/Sign";
import { IoMdCheckmarkCircleOutline } from "@react-icons/all-files/io/IoMdCheckmarkCircleOutline";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ShareDialog from "../components/ShareDialog";
import dateImage from "../images/date.png";
import timeImage from "../images/time.png";
import durationImage from "../images/duration.png";
import priceImage from "../images/price.png";
import Video_dialog from "../Video_dialog";
import Meta from "../meta/Meta";
import RegistrationForm from "./RegistrationForm";
import ShareNews from "../govt-circular-gr/ShareNews";
const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
  speakerImage: {
    width: "50%",
    height: "auto",
    borderRadius: "50%",

    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
  },
  speakerBody: {
    position: "absolute",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
    [theme.breakpoints.between("md", "lg")]: {
      /* Apply styles for large mobile screens */ position: "absolute",
    },
    [theme.breakpoints.between(750, 950)]: {
      /* Your styles for devices between 800px and 950px */
      /* For example: */
      position: "absolute",
    },
    // [theme.breakpoints.down("lg")]: {
    //   position: "absolute",
    // },
  },
});
const orgGrades = getGrades()
  ? getGrades()
      .split(",")
      .map((grade) => grade.trim())
  : [];
const orgSubjects = getSubjects()
  ? getSubjects()
      .split(",")
      .map((sub) => sub.trim())
  : [];
const initialValues = {
  firstname: getName(),
  lastname: getLastName(),
  role: getRole(),
  contact: getContact(),
  email: getEmail(),
  city: getCity(),
  schcollname: getSchCollName(),
  pincode: getPincode(),
  board: getBoard(),
  grades: orgGrades,
  subjects: orgSubjects,
};
class Webinar extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      eventData: [],
      noData: "",
      points: [],
      speakers: [],
      joinNow: false,
      showComponent: false,
      title: "",
      joinNow: "",
      name: getName(),
      email: getEmail(),
      contact: getContact(),
      dialogOpen: false,
      videoUrl: "",
      showShareDialog: false,
      is_bookmark: false,
      isMobile: false,
    };

    this._sign = React.createRef();
    this.getWebinar = this.getWebinar.bind(this);
    this.checkUserRegister = this.checkUserRegister.bind(this);
    this.registerNow = this.registerNow.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.getWebinar();
    this.checkUserRegister();
    this.setState({ isMobile: window.innerWidth <= 1000 });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.webinarId !== this.props.match.params.webinarId
    ) {
      window.scrollTo(0, 0);
      this.getWebinar();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleRegistrationSuccess = (isJoined, webinarId) => {
    if (isJoined) {
      this.setState({ joinNow: true });
    }
  };
  checkUserRegister = () => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      webinar_id: this.props.match.params.webinarId,
      user_id: getID(),
    };
    axios
      .post(apiURL("join_webinar_user/check_join_user"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({ joinNow: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getWebinar = () => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      webinar_id: this.props.match.params.webinarId,
      user_id: getID(),
    };
    axios
      .post(apiURL("webinar/get_webinar_by_id"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const eventData = resultData.result;
          const points = JSON.parse(resultData.result.fldt_points);
          const speakers = resultData.result.speaker;
          const is_bookmark = eventData.is_bookmark;
          this.setState({
            eventData,
            points,
            speakers,
            is_bookmark,
          });
        } else {
          this.setState({ noData: "Coming Soon" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  registerNow = () => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      webinar_id: this.props.match.params.webinarId,
      user_id: getID(),
    };
    axios
      .post(apiURL("join_webinar_user/add"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({ joinNow: true });
          this.componentDidMount();
          this.setState({
            registerDialog: false,
            name: "",
            email: "",
            contact: "",
            showSnackbar: true,
            snackBarMessage:
              "Thank you for getting in touch! We appreciate you contacting us....",
            severityColor: "success",
            snackBarTimer: "3000",
          });
        } else {
          this.setState({ noData: "Coming Soon" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleShareClick = () => {
    this.setState({ showShareDialog: true });
  };

  handleCloseShareDialog = () => {
    this.setState({ showShareDialog: false });
  };
  // this is for webinar check login to join now button
  checkJoinNow = () => {
    this.checkUserRegister();
    this.getWebinar();
    this.setState({
      showComponent: false,
      registerDialog: true,
      name: getName(),
      email: getEmail(),
      contact: getContact(),
    });
  };

  //   handleClickOpen = () => {
  //         if(!isLogin())
  //         {
  //         this.setState({ showComponent: true })
  //         }
  //         else{
  //         // this.setState({ open: true })
  //         this.registerNow();
  //         }
  //     }*

  handleClickOpen = (e, webinarId, title, date, time) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      // this.setState({ open: true })
      //this.registerNow();
      this.setState({
        registerDialog: true,
        id: webinarId,
        title: title,
        date: date,
        time: time,
      });
    }
  };
  handleBack = (event) => {
    this.props.history.push("/upcoming-webinars");
  };
  registerWebinarClose = () => {
    this.setState({ registerDialog: false, id: "", title: "" });
  };

  videoDialog = (e, videoUrl) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.setState({
        dialogOpen: true,
        videoUrl: videoUrl,
      });
    }
  };

  videoDialogClose = () => {
    //alert("close");
    this.setState({
      dialogOpen: false,
      videoUrl: "",
    });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };
  handleAddBookMark = (id) => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        //alert(resultData.message);
        if (resultData.success === true || resultData.success === 1) {
          this.setState({
            is_bookmark: true,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleRemoveBookMark = (id) => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            is_bookmark: false,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { classes } = this.props;
    const { showShareDialog, isMobile } = this.state;

    return (
      <React.Fragment>
        <Meta
          tableName={"webinar"}
          objectId={this.props.match.params.webinarId}
          forPage={"inner_page"}
        />
        {this.state.showComponent ? (
          <Sign
            ref={this._sign}
            pageProp={this.props}
            checkJoinNow={(e) => this.checkJoinNow(e)}
          />
        ) : (
          <div>
            <Appbar />
            <div className="sticky-top sign-light">
              <Navmenu />
            </div>
            <section className="upcomingWebMain">
              <Container>
                <h1>{this.state.eventData.fldv_title}</h1>
                <List inline>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                </List>
                <Row className="d-flex">
                  <Col
                    lg="6"
                    md="12"
                    sm="12"
                    className="order-lg-1 order-md-1 order-1"
                  >
                    <h5 className="speakerName">
                      {this.state.eventData.fldv_speaker_name}
                    </h5>
                    {/* <h5>{this.state.points.map((point) => point.point)}</h5> */}
                    <div className="fixed-content">
                      <SanitizedHTML
                        allowedAttributes={false}
                        allowedTags={false}
                        html={this.state.eventData.fldv_description}
                      />
                    </div>
                  </Col>
                  <Col
                    lg="6"
                    md="12"
                    sm="12"
                    className="webVideo order-lg-2 order-md-2 order-2"
                  >
                    {this.state.eventData.fldv_event_image !== "" ? (
                      <div className="player-wrapper">
                        <img
                          src={this.state.eventData.fldv_event_image}
                          className="react-player"
                          controls="true"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    ) : (
                      <div className="player-wrapper"></div>
                    )}

                    {/* <Video /> */}
                  </Col>{" "}
                </Row>
                <Row>
                  <Col
                    lg="6"
                    md="12"
                    sm="12"
                    className="order-lg-1 order-md-1 order-1"
                  >
                    <table className="table">
                      {/* <thead>
                                    <tr>
                                      <th>Key</th>
                                      <th>Value</th>
                                    </tr>
                                  </thead> */}
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src={dateImage}
                              className="img-fluid"
                              // width="30px"
                            />{" "}
                            :{" "}
                            {Moment(
                              this.state.eventData.fldv_select_date
                            ).format("ddd, MMMM Do, YYYY, hh:mmA")}
                          </td>
                          <td>
                            {" "}
                            <img
                              src={durationImage}
                              className="img-fluid"
                              // width="30px"
                            />{" "}
                            : {this.state.eventData.fldv_duration} Hours
                          </td>
                          {/* <td>
                            <img
                              src={timeImage}
                              className="img-fluid"
                              width="30px"
                            />{" "}
                            :{" "}
                            {Moment(this.state.eventData.fldt_time).format(
                              "hh:mmA"
                            )}
                          </td> */}
                        </tr>
                        <tr>
                          <td>
                            <LocationOn
                              color="primary"
                              // style={{ fontSize: "30" }}
                            />
                            : {this.state.eventData.fldv_venue_location}
                          </td>
                          <td>
                            {" "}
                            <img
                              src={priceImage}
                              className="img-fluid"
                              // width="30px"
                            />{" "}
                            : {this.state.eventData.fldv_price}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col
                    lg="6"
                    md="12"
                    sm="12"
                    className="webVideo order-lg-2 order-md-2 order-2"
                  >
                    <div className="register mt-2 d-flex justify-content-center">
                      {this.state.joinNow ? (
                        <Button
                          style={{ backgroundColor: "#064e89", color: "#fff" }}
                        >
                          Registered
                          <span>
                            <IoMdCheckmarkCircleOutline />
                          </span>
                        </Button>
                      ) : (
                        <Button
                          style={{ backgroundColor: "#064e89", color: "#fff" }}
                          onClick={(e) =>
                            this.handleClickOpen(
                              e,
                              this.state.eventData.fldi_id,
                              this.state.eventData.fldv_title,
                              Moment(
                                this.state.eventData.fldv_select_date
                              ).format("MM DD YYYY"),
                              Moment(this.state.eventData.fldt_time).format(
                                "LT"
                              )
                            )
                          }
                        >
                          Register
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>

            <section className="buttonContainer">
              <Container>
                <div className="action">
                  {this.state.is_bookmark === true ? (
                    <IconButton
                      variant="contained"
                      className="bookmark"
                      style={{
                        color: "orange",
                        border: "3px solid #064e89",
                      }}
                      onClick={() =>
                        this.handleRemoveBookMark(this.state.eventData.fldi_id)
                      }
                    >
                      <Bookmark />
                    </IconButton>
                  ) : (
                    <IconButton
                      variant="contained"
                      className="bookmark"
                      style={{
                        color: "#064e89",
                        border: "3px solid #064e89",
                      }}
                      onClick={() =>
                        this.handleAddBookMark(this.state.eventData.fldi_id)
                      }
                    >
                      <BookmarkBorder />
                    </IconButton>
                  )}
                </div>
                <div className="shareSection">
                  <ShareNews
                    trainingId={this.state.eventData.fldi_id}
                    shareUrl={
                      this.state.eventData.fldv_webinar_url !== ""
                        ? this.state.eventData.fldv_webinar_url
                        : "https://navdisha.navneet.com/"
                    }
                    title={this.state.eventData.fldv_title}
                    date={Moment(this.state.eventData.fldv_select_date).format(
                      "dddd, D MMM, YYYY"
                    )}
                    time={Moment(this.state.eventData.fldv_from_time).format(
                      "hh:mmA"
                    )}
                  />
                </div>
                <Row>
                  <Button
                    style={{ background: "#064e89", color: "white" }}
                    variant="contained"
                    onClick={this.handleBack}
                    className="back"
                  >
                    Back
                  </Button>
                </Row>
              </Container>
            </section>

            <Footer />
            <ShareDialog
              open={showShareDialog}
              onClose={this.handleCloseShareDialog}
              webinarId={this.state.eventData.fldi_id}
              shareUrl={this.state.eventData.fldv_webinar_url}
              title={this.state.eventData.fldv_title}
              date={Moment(this.state.eventData.fldv_select_date).format(
                "dddd, D MMM, YYYY"
              )}
              time={Moment(this.state.eventData.fldt_time).format("hh:mmA")}
            />
            <Dialog
              onClose={(e) => this.registerWebinarClose(e)}
              aria-labelledby="customized-dialog-title"
              open={this.state.registerDialog}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={(e) => this.registerWebinarClose(e)}
              >
                {this.state.eventData.fldv_title}
              </DialogTitle>
              <DialogContent>
                {/*<form id="login" onSubmit={(e) => this.registerNow(e)}>*/}
                <RegistrationForm
                  id={this.state.id}
                  userData={initialValues}
                  date={this.state.date}
                  time={this.state.time}
                  onClose={this.registerWebinarClose}
                  onSuccess={(isJoined) =>
                    this.handleRegistrationSuccess(isJoined, this.state.id)
                  }
                />
                {/*</form>*/}
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>

            <Snackbar
              open={this.state.showSnackbar === true}
              autoHideDuration={this.state.snackBarTimer}
              onClose={this.snackBarClose}
            >
              <Alert
                onClose={this.snackBarClose}
                severity={this.state.severityColor}
                elevation={6}
                variant="filled"
              >
                {this.state.snackBarMessage}
              </Alert>
            </Snackbar>

            <Video_dialog
              dialogOpen={this.state.dialogOpen}
              videoUrl={this.state.videoUrl}
              onClose={(e) => this.videoDialogClose(e)}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Webinar);
