import React, { Fragment } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import "../style/Appbar.scss";
import Button from "@material-ui/core/Button";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import { isLogin, getName, getUsername, getID } from "../utils";
import { Link, useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import logo from "../images/navdisha.png";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import logonew from "../images/Navdisha-Logo-2024-20240430-062400.jpg";
import logo1 from "../images/Navdisha-Logo-new.jpg";
import Navmenu from "../components/Navmenu";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Tooltip, Paper } from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { apiURL } from "../utils";
import axios from "axios";
import Moment from "moment";
const useStyles = makeStyles((theme) => ({
  popover: {
    maxWidth: "300px",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  const [otherNotification, setOtherNotification] = useState([]);
  const [count, setCount] = useState(0);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleToggle = () => {
    if (!isLogin()) {
      history.push("/sign");
    } else {
      setOpen(!open);
      if (!open) {
        fetchNotification();
      }
    }
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const history = useHistory();

  const handleLogOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.localStorage.clear();
    setOpen(false);
    handleMenuClose();
    history.push("/");
  };
  const handleProfile = (e) => {
    e.preventDefault();
    history.push("/profile-info");
  };
  const handleBookMark = (e) => {
    e.preventDefault();
    history.push("/bookmark");
  };
  const handleAchievement = (e) => {
    e.preventDefault();
    history.push("/achievements");
  };
  const fetchNotification = () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .get(apiURL("Site_notification/notifications/" + getID()), {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;

        if (resultData.status === true || resultData.status === 1) {
          setUpcomingEvent(resultData.notifications.webinar);
          setOtherNotification(resultData.notifications.others);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchCount = () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      user_id: getID(),
    };
    axios
      .post(apiURL("Site_notification/count_notification"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;

        if (resultData.status === true || resultData.status === 1) {
          setCount(resultData.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleReadMore = (
    e,
    id,
    title,
    type,
    notification_id,
    event_type,
    category
  ) => {
    if (type === "Upcoming" && event_type === "Cancelled") {
      readNotification(notification_id);
      setOpen(false);
    } else if (type === "Upcoming" && event_type !== "Cancelled") {
      history.push("/webinar/" + id + "/" + title);
    } else if (type === "Past Webinar") {
      history.push("/past-webinar-schedule/" + id + "/" + title);
    } else if (type === "Past Training") {
      history.push("/training/" + id + "/" + title);
    } else if (type === "Articles") {
      history.push("/article/" + id + "/" + title, { category });
    } else if (type === "Blogs") {
      history.push("/blog/" + id + "/" + title, { category: "Blogs" });
    } else if (type === "Govt.Circulars/GR") {
      history.push("/news/" + id + "/" + title);
    } else if (type === "Videos") {
      history.push("/video");
    }
    readNotification(notification_id);
  };
  const readNotification = (id) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      notification_id: id,
      user_id: getID(),
    };
    axios
      .post(apiURL("Site_notification/mark_as_read/"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          fetchCount();
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function timeSince(dateString) {
    const time = Moment(dateString, "hh:mm:ss");

    const dateTimeAgo = Moment(new Date(dateString))
      .startOf("seconds")
      .fromNow();
    return dateTimeAgo;
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className="profileMenu"
      variant="popover"
    >
      <MenuItem onClick={handleProfile}>Profile</MenuItem>
      <MenuItem onClick={handleBookMark}>My Bookmarks</MenuItem>
      <MenuItem onClick={handleAchievement}>My Achievements</MenuItem>
      <MenuItem onClick={handleLogOut}>
        Logout
        <ExitToAppIcon className="ml-2 exitIcon" />
      </MenuItem>

      {/*<MenuItem onClick={handleMenuClose}>Profile </MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>*/}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "right", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className="profileMobileMenu"
    >
      <MenuItem>
        {isLogin() ? (
          <Button onClick={handleProfileMenuOpen}> {getUsername()} </Button>
        ) : (
          <Link to={"/sign"}>
            <Button> SIGN IN </Button>
          </Link>
        )}
      </MenuItem>

      <MenuItem>
        <PopupState variant="popover" popupId="demo-popup-popover">
          {(popupState) => (
            <div>
              {/* <Button variant="contained" color="primary" >
            Open Popover
          </Button> */}
              <div {...bindTrigger(popupState)}>
                <IconButton
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={4} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </div>

              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "right",
                  horizontal: "right",
                }}
              >
                <Box p={0}>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Lorem Ipsum has been the industry's  standard dummy text ever sinces"
                        secondary="Jan 9, 2014"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <WorkIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Lorem Ipsum has been the industry's standard dummy text ever since"
                        secondary="Jan 7, 2014"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <BeachAccessIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Vacation"
                        secondary="July 20, 2014"
                      />
                    </ListItem>
                  </List>
                </Box>
              </Popover>
            </div>
          )}
        </PopupState>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    ReactGA.initialize("UA-179012541-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    //console.log('this url '+window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    if (isLogin()) {
      fetchCount();
      const intervalId = setInterval(fetchCount, 10000);

      return () => clearInterval(intervalId);
    }
  }, []);
  return (
    <div className={classes.grow} className="mobileSticky   pb-3 sticky-top">
      {/* <AppBar position="static"  className="customBar1 sticky-top"> */}
      <AppBar position="static" className="customBar1 sticky-top">
        <Row className="mx-0">
          <Col>
            <Toolbar className="px-0">
              {/* <img src="../assets/images/logo.png" className="img-fluid logoBox"  />  */}

              <div className="d-flex justify-content-between w-100">
                <div>
                  <Link to={"/"}>
                    <img
                      src={logonew}
                      className="img-fluid logoBox"
                      width="179px"
                      height="69px"
                    />
                  </Link>
                </div>
                <div>
                  {" "}
                  <Navmenu />{" "}
                </div>
                <div>
                  <div className={classes.grow} />
                  <div className={classes.sectionDesktop}>
                    {isLogin() ? (
                      <Button onClick={handleProfileMenuOpen}>
                        <PersonAddIcon className="d-block d-lg-none " />{" "}
                        <span className="d-none d-lg-block">
                          {" "}
                          {getUsername()}
                        </span>
                      </Button>
                    ) : (
                      <Tooltip title={"Existing Users"}>
                        <Link
                          to={{
                            pathname: "/sign",
                            state: { value: 0 },
                          }}
                        >
                          <Button>
                            {" "}
                            <PersonAddIcon className="d-block d-lg-none " />{" "}
                            <span className="d-none d-lg-block"> SIGN IN </span>{" "}
                          </Button>
                        </Link>
                      </Tooltip>
                    )}

                    <div className="notificationBox">
                      <IconButton color="inherit" onClick={handleToggle}>
                        <Badge badgeContent={count} color="secondary">
                          <NotificationsIcon />
                        </Badge>
                      </IconButton>
                      {open && (
                        <Paper elevation={3} className="notificationPaper">
                          {upcomingEvent.length !== 0 && (
                            <Fragment>
                              {upcomingEvent.map((event) => (
                                <div
                                  className="eventContainer"
                                  onClick={(e) =>
                                    handleReadMore(
                                      e,
                                      event.content_id,
                                      event.content
                                        .replace(/ /g, "-")
                                        .toLowerCase(),
                                      event.type,
                                      event.id,
                                      event.event_type
                                    )
                                  }
                                >
                                  <div className="eventIcon">
                                    <EventAvailableIcon />
                                  </div>
                                  <div className="eventInfo">
                                    <h4>
                                      {event.event_type === "Cancelled"
                                        ? "Event Cancelled"
                                        : event.event_type === "Edited"
                                        ? "Event Updated"
                                        : "New Event Added"}
                                    </h4>
                                    <p>Upcoming Event - {event.content}</p>
                                    <div className="updates">
                                      <span>
                                        Updated: {timeSince(event.created_at)}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Fragment>
                          )}
                          {otherNotification !== 0 && (
                            <Fragment>
                              {otherNotification.map((event) => (
                                <div
                                  className="eventContainer"
                                  onClick={(e) =>
                                    handleReadMore(
                                      e,
                                      event.content_id,
                                      event.content
                                        .replace(/ /g, "-")
                                        .toLowerCase(),
                                      event.type,
                                      event.id,
                                      event.event_type,

                                      event.category_name
                                    )
                                  }
                                >
                                  <div className="eventIcon">
                                    <EventAvailableIcon />
                                  </div>
                                  <div className="eventInfo">
                                    <p>
                                      {event.type} - {event.content}
                                    </p>
                                    <div className="updates">
                                      <span>
                                        Updated: {timeSince(event.created_at)}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Fragment>
                          )}
                        </Paper>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Toolbar>
          </Col>
        </Row>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
