import React from "react";
import { Dialog, DialogTitle, DialogContent, Grid } from "@material-ui/core";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  InstapaperShareButton,
  InstapaperIcon,
} from "react-share";
import axios from "axios";
import { apiURL } from "../utils";
const ShareDialog = ({
  open,
  onClose,
  shareUrl,
  title,
  date,
  time,
  webinarId,
  trainingId,
  articleId,
  blogId,
}) => {
  let shareTitle = title;
  if (date && time) {
    shareTitle += ` - Date: ${date}, Time: ${time}`;
  } else if (date) {
    shareTitle += ` - Date: ${date}`;
  }
  const handleShareCount = async () => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      webinar_id: webinarId || "",
      training_id: trainingId || "",
      article_id: articleId || "",
      blog_id: blogId || "",
    };
    try {
      const response = await axios.post(apiURL("/share/add_share"), body, {
        headers: headers,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Share</DialogTitle>
      <DialogContent>
        <div className="float-right ">
          {/*<div className="Demo__some-network">
            <InstapaperShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <InstapaperIcon size={32} round />
            </InstapaperShareButton>
          </div>*/}

          <div className="Demo__some-network float-right ml-2">
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
              onClick={handleShareCount}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>

          <div className="Demo__some-network float-right ml-2">
            <LinkedinShareButton
              url={shareUrl}
              className="Demo__some-network__share-button"
              onClick={handleShareCount}
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>

          <div className="Demo__some-network float-right ml-2">
            <TelegramShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
              onClick={handleShareCount}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>
          <div className="Demo__some-network float-right ml-2">
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="Demo__some-network__share-button"
              onClick={handleShareCount}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div className="Demo__some-network float-right ml-2">
            <WhatsappShareButton
              url={shareUrl}
              title={shareTitle}
              separator=":: "
              className="Demo__some-network__share-button "
              onClick={handleShareCount}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShareDialog;
