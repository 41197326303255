import React, { Component } from "react";

import "../style/HomeEvents.scss";
import { Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";
import { IoMdCheckmarkCircleOutline } from "@react-icons/all-files/io/IoMdCheckmarkCircleOutline";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  apiURL,
  isLogin,
  getName,
  getEmail,
  getContact,
  getID,
} from "../utils";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Snackbar,
  IconButton,
  Container,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import Moment from "moment";
import Sign from "../login/Sign";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RegistrationForm from "../webinar/RegistrationForm";
import { BookmarkBorder, Bookmark } from "@material-ui/icons";

class UpcomingEvHome extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      upcomeingEventsList: [],
      noData: "",
      registerDialog: false,

      userData: [],
      title: "",
      id: "",
      joinNow: "",
      alreadyRegister: false,
      userId: "",
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
      page: 1,
      per: window.innerWidth <= 1000 ? 1 : 2,
      loading: false,
      authEmail: "",
      otpBoxOpen: false,
      isEmailAdresssVerified: false,
      email: "",
      emailError: false,
      emailErrorMsg: "",
      isEmailVerified: false,
      isEmailVerifiedMsg: "",
      open: false,
      selectedItem: 0,
      isMobile: false,
    };
    this.getUpComeinEvents = this.getUpComeinEvents.bind(this);
    this.getUserData = this.getUserData.bind(this);
    // this.registerNow = this.registerNow.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
    this.getUpComeinEvents();
    this.getUserData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.registerDialog !== this.state.registerDialog &&
      this.state.registerDialog
    ) {
      this.getUserData();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  getUserData = () => {
    const body = {
      fldi_id: getID(),
    };
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    axios
      .post(apiURL("teacher/get_profile"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const firstname =
            resultData.result.fldv_first_name !== ""
              ? resultData.result.fldv_first_name
              : resultData.result.fldv_name;
          const lastname =
            resultData.result.fldv_last_name !== ""
              ? resultData.result.fldv_last_name
              : resultData.result.fldv_surname;
          const role = resultData.result.fldv_faculty_status;
          const contact = resultData.result.fldv_contact;
          const email = resultData.result.fldv_email;
          const city = resultData.result.fldi_city;
          const schcollname = resultData.result.fldv_school_college;
          const pincode = resultData.result.fldv_pincode;
          const board = resultData.result.fldv_board_medium;

          const grades = resultData.result.fldv_grades_taught
            ? resultData.result.fldv_grades_taught
                .split(",")
                .map((grade) => grade.trim())
            : [];

          const subjects = resultData.result.fldv_subjects_taught
            ? resultData.result.fldv_subjects_taught
                .split(",")
                .map((sub) => sub.trim())
            : [];
          const userData = {
            firstname,
            lastname,
            role,
            city,
            contact,
            email,
            schcollname,
            pincode,
            board,
            grades,
            subjects,
          };
          this.setState({
            userData: userData,
          });
        } else {
          this.setState({
            noData: "Coming Soon",
            loading: false,
            scrolling: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getUpComeinEvents = () => {
    this.setState({ loading: true });
    const { per, page } = this.state;
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      status: 1,
      event_type: "UE",
      user_id: getID(),
    };
    axios
      .post(apiURL("webinar/events?per_page=" + 4 + "&page=" + 1), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            upcomeingEventsList: resultData.result,
          });
        } else {
          this.setState({
            noData: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleRegistrationSuccess = (isJoined, webinarId) => {
    console.log(webinarId);
    const updatedEventsList = this.state.upcomeingEventsList.map((event) => {
      if (event.fldi_id === webinarId) {
        return { ...event, join_webinar: isJoined };
      }
      return event;
    });

    this.setState({ upcomeingEventsList: updatedEventsList });
  };
  registerForWebinar = (e, webinarId, title, date, time) => {
    e.stopPropagation();
    if (!isLogin()) {
      this.props.history.push("/sign");
    } else {
      // this.getUpComeinEvents();
      this.setState({
        registerDialog: true,
        id: webinarId,
        title: title,
        date: date,
        time: time,
      });
    }
  };

  registerWebinarClose = () => {
    this.setState({ registerDialog: false, id: "", title: "" });
    this.getUserData();
  };
  handleReadMore = (e, id, title) => {
    e.preventDefault();
    if (!isLogin()) {
      this.props.history.push("/sign");
    } else {
      this.props.history.push("/webinar/" + id + "/" + title);
    }
  };

  handleLanguageChange = (e) => {
    const selectedLan = e.target.value;
    this.setState({ selectedLan });
  };
  handleAddBookMark = (e, id) => {
    e.stopPropagation();
    if (!isLogin()) {
      this.props.history.push("/sign");
    } else {
      const headers = {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "past_webinar",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          //alert(resultData.message);
          if (resultData.success === true || resultData.success === 1) {
            const index = this.state.upcomeingEventsList.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...this.state.upcomeingEventsList];
              updatedEventsList[index].is_bookmark = true;
              this.setState({
                upcomeingEventsList: updatedEventsList,
              });
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  handleRemoveBookMark = (e, id) => {
    e.stopPropagation();
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.upcomeingEventsList.map(
            (event) => {
              if (event.fldi_id === id) {
                return {
                  ...event,
                  is_bookmark: false,
                };
              }
              return event;
            }
          );
          this.setState({
            upcomeingEventsList: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleModeChange = (e) => {
    const selectedMode = e.target.value;
    this.setState({ selectedMode });
  };
  handlePrev = () => {
    this.setState((prevState) => ({
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  handleNext = () => {
    const { selectedItem, per } = this.state;
    const totalPages = Math.ceil(this.state.upcomeingEventsList.length / per);

    this.setState((prevState) => ({
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };
  render() {
    const { classes } = this.props;

    const { upcomeingEventsList, selectedItem, isMobile } = this.state;
    const displayData = isMobile
      ? upcomeingEventsList.slice(selectedItem, selectedItem + 1)
      : upcomeingEventsList.slice(selectedItem * 2, selectedItem * 2 + 2);
    const singleData = displayData.length === 1;
    console.log(displayData);
    return (
      <React.Fragment>
        {upcomeingEventsList.length !== 0 && (
          <div>
            <section class="mainEveSection">
              <h2 className="text-center">Upcoming Events</h2>
              <Container maxWidth="lg" className="container">
                <Carousel
                  ref={this.carouselRef}
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={false}
                  showIndicators={false}
                  showStatus={false}
                  selectedItem={selectedItem}
                  onChange={(index) => this.setState({ selectedItem: index })}
                >
                  <Row className="upcomingWebHome position-relative">
                    {displayData.map((arrList, index) => {
                      return (
                        <Col
                          col="12 col-lg-6 col-xl-4"
                          className="mx-auto custom-col-spacing"
                        >
                          <Card
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              this.handleReadMore(
                                e,
                                arrList.fldi_id,
                                arrList.fldv_title
                                  .replace(/ /g, "-")
                                  .toLowerCase()
                              )
                            }
                          >
                            <Card.Body>
                              <div className="position-relative">
                                {/* <div className="SpeakerBox text-center"> */}
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <div className="Speaker">
                                      <Button
                                        className="mode"
                                        style={{
                                          background:
                                            arrList.fldv_mode === "Physical"
                                              ? "#064e89"
                                              : "#a03fa0",
                                        }}
                                      >
                                        {arrList.fldv_mode}
                                      </Button>
                                      <figure>
                                        <div className="d-block imgBox">
                                          <img
                                            src={arrList.fldv_image_url}
                                            className="img-fluid"
                                            alt="img"
                                          />
                                        </div>
                                        <p>{arrList.fldv_speaker_name}</p>
                                      </figure>
                                    </div>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <table className="table">
                                      <tr>
                                        <th colSpan="2" className="topic">
                                          {arrList.fldv_title}
                                        </th>
                                      </tr>

                                      <tbody>
                                        <tr>
                                          <th>Date:</th>
                                          <td>
                                            {Moment(
                                              arrList.fldv_select_date
                                            ).format("ddd, D MMM, YYYY")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Time:</th>
                                          <td>
                                            {Moment(arrList.fldt_time).format(
                                              "LT"
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Duration:</th>
                                          <td>{arrList.fldv_duration} hrs</td>
                                        </tr>
                                        <tr>
                                          <th>Venue:</th>
                                          <td>{arrList.fldv_venue_location}</td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <div className="action">
                                      <div className="readmoreContainer">
                                        {arrList.join_webinar ? (
                                          <Button className="registered">
                                            {" "}
                                            Registered{" "}
                                            <span>
                                              <IoMdCheckmarkCircleOutline />
                                            </span>
                                          </Button>
                                        ) : (
                                          <Button
                                            className="readmore"
                                            onClick={(e) =>
                                              this.registerForWebinar(
                                                e,
                                                arrList.fldi_id,
                                                arrList.fldv_title,
                                                Moment(
                                                  arrList.fldv_select_date
                                                ).format("MM DD YYYY"),
                                                Moment(
                                                  arrList.fldt_time
                                                ).format("LT")
                                              )
                                            }
                                          >
                                            {" "}
                                            Register{" "}
                                          </Button>
                                        )}
                                      </div>
                                      <div className="bookmarkContainer">
                                        {arrList.is_bookmark ? (
                                          <IconButton
                                            style={{ color: "orange" }}
                                            className="bookmark"
                                            onClick={(e) =>
                                              this.handleRemoveBookMark(
                                                e,
                                                arrList.fldi_id
                                              )
                                            }
                                          >
                                            <Bookmark />
                                          </IconButton>
                                        ) : (
                                          <IconButton
                                            className="bookmark"
                                            onClick={(e) =>
                                              this.handleAddBookMark(
                                                e,
                                                arrList.fldi_id
                                              )
                                            }
                                          >
                                            <BookmarkBorder />
                                          </IconButton>
                                        )}
                                      </div>
                                    </div>
                                  </Grid>
                                  {/* </div> */}
                                </Grid>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Carousel>
                <IconButton
                  onClick={this.handlePrev}
                  className={singleData ? "singlePrevButton" : "prevButton"}
                >
                  <ArrowLeftIcon />
                </IconButton>
                <IconButton
                  onClick={this.handleNext}
                  className={singleData ? "singleNextButton" : "nextButton"}
                >
                  <ArrowRightIcon />
                </IconButton>
              </Container>
            </section>

            <Dialog
              onClose={(e) => this.registerWebinarClose(e)}
              aria-labelledby="customized-dialog-title"
              open={this.state.registerDialog}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={(e) => this.registerWebinarClose(e)}
              >
                {this.state.title}
              </DialogTitle>
              <DialogContent>
                {/*<form id="login" onSubmit={(e) => this.registerNow(e)}>*/}
                <RegistrationForm
                  id={this.state.id}
                  userData={this.state.userData}
                  date={this.state.date}
                  time={this.state.time}
                  onClose={this.registerWebinarClose}
                  onSnackClose={this.snackBarClose}
                  componentUpdate={this.getUpComeinEvents}
                  onSuccess={(isJoined) =>
                    this.handleRegistrationSuccess(isJoined, this.state.id)
                  }
                />
                {/*</form>*/}
              </DialogContent>
              <DialogActions>
                {/*<Button autoFocus onClick={(e) => this.registerTrainingClose(e)} color="primary">
                  Save changes
                </Button>*/}
              </DialogActions>
            </Dialog>
            <Snackbar
              open={this.state.showSnackbar === true}
              autoHideDuration={this.state.snackBarTimer}
              onClose={this.snackBarClose}
            >
              <Alert
                onClose={this.snackBarClose}
                severity={this.state.severityColor}
                elevation={6}
                variant="filled"
              >
                {this.state.snackBarMessage}
              </Alert>
            </Snackbar>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default UpcomingEvHome;
