import React, { Fragment } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import SanitizedHTML from "react-sanitized-html";
const NewsDescriptionDialog = ({ dialogOpen, title, description, onClose }) => {
  return (
    <Fragment>
      <Dialog
        fullWidth="true"
        maxWidth="md"
        open={dialogOpen}
        onClose={onClose}
      >
        <DialogContent>
          <DialogContentText style={{ fontSize: "0.9rem", color: "black" }}>
            <iframe
              src={description}
              width="100%"
              height="400px"
              title="news-content"
              style={{ border: "none" }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default NewsDescriptionDialog;
