import React, { Component } from "react";
import { Row, Col, Card } from "bootstrap-4-react";
import { IconButton, Button, Container } from "@material-ui/core";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../../utils";
import Meta from "../../meta/Meta";
import "../../style/BookMark.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Moment from "moment";
class GovtCircularBookmark extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      per: window.innerWidth <= 1000 ? 1 : 2,

      categoryID: null,
      noData: "",
      selectedTab: 0,
      loading: true,
      selectedItem: 0,
      circularData: this.props.circularData,
      isMobile: false,
    };
    // this.carouselRef = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.circularData !== this.props.circularData) {
      this.setState({ circularData: this.props.circularData });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  redirectArticle = (e, id, title, category) => {
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      this.props.history.push(
        "/news/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
        { category }
      );
    }
  };

  handleRemoveBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { selectedItem, per } = this.state;
    const { circularData, handleRemoveGRBookMark } = this.props;
    this.props.handleRemoveGRBookMark(id);
    const updatedEvents = circularData.filter((event) => event.news_id !== id);
    const totalPages = Math.ceil(updatedEvents.length / per);

    if (selectedItem >= totalPages && totalPages > 0) {
      this.setState({ selectedItem: totalPages - 1 });
    }
  };
  handlePrev = () => {
    this.setState((prevState) => ({
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  handleNext = () => {
    const { selectedItem, per } = this.state;
    const totalPages = Math.ceil(this.props.circularData.length / per);

    this.setState((prevState) => ({
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };
  render() {
    const { id } = this.props;
    const { selectedItem, per, circularData, isMobile } = this.state;

    const displayData = isMobile
      ? circularData.slice(selectedItem, selectedItem + 1)
      : circularData.slice(selectedItem * 2, selectedItem * 2 + 2);
    const singleData = displayData.length === 1;
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />

        <div>
          {/* <section className="circularSection"> */}
          {/* <Container> */}
          <section className="circularGrSection">
            <Container maxWidth="lg" className="container">
              <h2>Govt. Circulars/GR</h2>
              <Carousel
                ref={this.carouselRef}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={selectedItem}
                onChange={(index) => this.setState({ selectedItem: index })}
              >
                <Row className="circularOtherBook">
                  {displayData.map((arrNews, index) => {
                    return (
                      <Col
                        key={index}
                        col="12 col-lg-6 col-xl-4"
                        className="mx-auto custom-col-spacing mt-4"
                      >
                        <Card
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            this.redirectArticle(
                              e,
                              arrNews.news_id,
                              arrNews.news_title,
                              this.props.category_name
                            )
                          }
                        >
                          <Card.Body>
                            <figure>
                              <div className="d-block mainImage">
                                <img
                                  src={arrNews.news_image_url}
                                  className="img-fluid"
                                  alt={arrNews.news_title}
                                />
                              </div>
                            </figure>
                            <div className="content">
                              <div className="topic">
                                <h4>{arrNews.news_title}</h4>
                              </div>
                              <div className="date">
                                <h4>
                                  {" "}
                                  {Moment(arrNews.news_selected_date).format(
                                    "D MMM, yyyy"
                                  )}
                                </h4>
                              </div>
                            </div>
                          </Card.Body>

                          <div className="action">
                            <div>
                              <Button
                                variant="contained"
                                size="small"
                                className="readmore"
                                onClick={(e) =>
                                  this.redirectArticle(
                                    e,
                                    arrNews.news_id,
                                    arrNews.news_title
                                  )
                                }
                              >
                                Read More
                              </Button>
                            </div>
                            <div>
                              <IconButton
                                style={{ color: "orange" }}
                                className="bookmark"
                                onClick={(e) =>
                                  this.handleRemoveBookMark(e, arrNews.news_id)
                                }
                              >
                                <Bookmark />
                              </IconButton>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Carousel>
              <IconButton
                onClick={this.handlePrev}
                className={singleData ? "singlePrevButton" : "prevButton"}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={this.handleNext}
                className={singleData ? "singleNextButton" : "nextButton"}
              >
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </section>
          {/* </Container>
          </section> */}
        </div>
      </React.Fragment>
    );
  }
}

export default GovtCircularBookmark;
