import React, { Component } from "react";
import { Row, Col, Card } from "bootstrap-4-react";
import { IconButton, Button, Container } from "@material-ui/core";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import Meta from "../meta/Meta";
import "../style/OtherGR.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Moment from "moment";
class OtherGr extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      per: 2,
      newsData: [],
      categoryID: null,
      noData: "",
      selectedTab: 0,
      loading: true,
      selectedItem: 0,
      isMobile: false,
    };
    this.circularListData = this.circularListData.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
    this.circularListData();
  }
  componentDidUpdate(prevProps, prevState) {}
  componentWillUnmount() {
    this._isMounted = false;
  }

  circularListData = () => {
    this.setState({ loading: true });
    const { per, page, newsData } = this.state;
    console.log(this.props);
    const headers = {
      "Content-type": "application/json",
    };
    const body = { id: "", user_id: getID(), board: this.props.boards };
    axios
      .post(apiURL("news/get_all_news"), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const updatedNewsList = [...newsData, ...resultData.result];
            this.setState({
              newsData: updatedNewsList,
              scrolling: false,
            });
            this.setState({ loading: false, scrolling: true });
          } else {
            this.setState({ noData: "", loading: false, scrolling: false });
          }
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  redirectArticle = (e, id, title, category) => {
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      this.props.history.push(
        "/news/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
        { category }
      );
    }
  };

  handleAddBookMark = (e, id) => {
    e.stopPropagation();
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      const headers = {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "news",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const index = this.state.newsData.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...this.state.newsData];
              updatedEventsList[index].is_bookmark = true;
              this.setState({
                newsData: updatedEventsList,
              });
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleRemoveBookMark = (e, id) => {
    e.stopPropagation();
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "news",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.newsData.map((event) => {
            if (event.fldi_id === id) {
              return {
                ...event,
                is_bookmark: false,
              };
            }
            return event;
          });
          this.setState({
            newsData: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handlePrev = () => {
    this.setState((prevState) => ({
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  handleNext = () => {
    const { selectedItem, per } = this.state;
    const totalPages = Math.ceil(this.state.newsData.length / per);
    this.setState((prevState) => ({
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };
  render() {
    const { id } = this.props;
    const { newsData, selectedItem, isMobile } = this.state;
    const filteredData = id
      ? newsData.filter((event) => event.fldi_id !== id)
      : newsData;
    const displayData = isMobile
      ? filteredData.slice(selectedItem, selectedItem + 1)
      : filteredData.slice(selectedItem * 2, selectedItem * 2 + 2);
    const singleData = displayData.length === 1;
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />

        <div>
          {/* <section className="circularSection"> */}
          {/* <Container> */}
          <section className="mainCircularOtherSection">
            <h2 className="text-center">Other Govt. Circulars/GR</h2>
            <Container className="container">
              <Carousel
                ref={this.carouselRef}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={selectedItem}
                onChange={(index) => this.setState({ selectedItem: index })}
              >
                <Row className="circularOther">
                  {displayData.map((arrNews, index) => {
                    return (
                      <Col
                        key={index}
                        col="12 col-lg-6 col-xl-4"
                        className="mx-auto custom-col-spacing mt-4"
                      >
                        <Card
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            this.redirectArticle(
                              e,
                              arrNews.fldi_id,
                              arrNews.fldv_title,
                              this.props.category_name
                            )
                          }
                        >
                          <Card.Body>
                            <figure>
                              <div className="d-block mainImage">
                                <img
                                  src={arrNews.fldv_image_url}
                                  className="img-fluid"
                                  alt={arrNews.fldv_title}
                                />
                              </div>
                            </figure>
                            <div className="content">
                              <div className="topic">
                                <h4>{arrNews.fldv_title}</h4>
                              </div>
                              <div className="date">
                                <h4>
                                  {" "}
                                  {Moment(arrNews.fldv_selected_date).format(
                                    "D MMM, yyyy"
                                  )}
                                </h4>
                              </div>
                            </div>
                          </Card.Body>

                          <div className="action">
                            <Button
                              variant="contained"
                              size="small"
                              className="readmore"
                              onClick={(e) =>
                                this.redirectArticle(
                                  e,
                                  arrNews.fldi_id,
                                  arrNews.fldv_title
                                )
                              }
                            >
                              Read More
                            </Button>

                            {arrNews.is_bookmark ? (
                              <IconButton
                                style={{ color: "orange" }}
                                className="bookmark"
                                onClick={(e) =>
                                  this.handleRemoveBookMark(e, arrNews.fldi_id)
                                }
                              >
                                <Bookmark />
                              </IconButton>
                            ) : (
                              <IconButton
                                className="bookmark"
                                onClick={(e) =>
                                  this.handleAddBookMark(e, arrNews.fldi_id)
                                }
                              >
                                <BookmarkBorder />
                              </IconButton>
                            )}
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Carousel>
              <IconButton
                onClick={this.handlePrev}
                className={singleData ? "singlePrevButton" : "prevButton"}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={this.handleNext}
                className={singleData ? "singleNextButton" : "nextButton"}
              >
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </section>
          {/* </Container>
          </section> */}
        </div>
      </React.Fragment>
    );
  }
}

export default OtherGr;
