import React, { Component, createRef } from "react";
import "../style/OtherWeb.scss";
import { Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import axios from "axios";
import Moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import { Snackbar, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import WebinarForm from "../webinar/WebinarForm";
import Video_dialog from "../Video_dialog";
import Meta from "../meta/Meta";
import { ArrowLeft, BookmarkBorder } from "@material-ui/icons";
import { Bookmark } from "@material-ui/icons";
import logoNew from "../images/navdisha.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
});

class PastWebinarHome extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      pastEventsList: [],
      id: "",
      title: "",
      page: 1,
      per: window.innerWidth <= 1000 ? 1 : 2,
      selectedItem: 0,
      isMobile: false,
    };
    this.carouselRef = createRef();
    this.getPastEvents = this.getPastEvents.bind(this);
  }

  handleClickOpen = (e, id, title) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.setState({ open: true, id: id, title: title });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.getPastEvents();
    this.setState({ isMobile: window.innerWidth <= 1000 });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleReadMore = (e, id, title) => {
    e.preventDefault();
    if (!isLogin()) {
      this.props.history.push("/sign");
    } else {
      this.props.history.push("/past-webinar-schedule/" + id + "/" + title);
    }
  };

  getPastEvents = () => {
    this.setState({ loading: true });
    const { per, page } = this.state;
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      status: 1,
      event_type: "PE",
      user_id: getID(),
    };
    axios
      .post(apiURL("webinar/events?per_page=" + 4 + "&page=" + 1), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            pastEventsList: resultData.result,
          });
        } else {
          this.setState({
            noData: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAddBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      const headers = {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "past_webinar",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const updatedEventsList = this.state.pastEventsList.map((event) =>
              event.fldi_id === id ? { ...event, is_bookmark: true } : event
            );
            this.setState({
              pastEventsList: updatedEventsList,
            });
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleRemoveBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.pastEventsList.map((event) =>
            event.fldi_id === id ? { ...event, is_bookmark: false } : event
          );
          this.setState({
            pastEventsList: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePrev = () => {
    this.setState((prevState) => ({
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  handleNext = () => {
    const { selectedItem, per } = this.state;
    const totalPages = Math.ceil(this.state.pastEventsList.length / per);
    this.setState((prevState) => ({
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };

  render() {
    const { classes, id } = this.props;
    const { pastEventsList, selectedItem, isMobile } = this.state;

    const displayData = isMobile
      ? pastEventsList.slice(selectedItem, selectedItem + 1)
      : pastEventsList.slice(selectedItem * 2, selectedItem * 2 + 2);
    const singleData = displayData.length === 1;
    return (
      <React.Fragment>
        {pastEventsList.length !== 0 && (
          <div>
            <section className="mainEveSection">
              <h2 className="text-center">Past Webinars</h2>
              <Container maxWidth="lg" className="container">
                <Carousel
                  ref={this.carouselRef}
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={false}
                  showIndicators={false}
                  showStatus={false}
                  selectedItem={selectedItem}
                  onChange={(index) => this.setState({ selectedItem: index })}
                >
                  <Row className="trainWebHome position-relative">
                    {" "}
                    {displayData.map((arrList) => (
                      <Col
                        col="12 col-lg-6 col-xl-4"
                        className="mx-auto custom-col-spacing"
                      >
                        <Card>
                          <Link>
                            <Card.Body
                              onClick={(e) =>
                                this.handleReadMore(
                                  e,
                                  arrList.fldi_id,
                                  arrList.fldv_title
                                    .replace(/ /g, "-")
                                    .toLowerCase()
                                )
                              }
                            >
                              <div className="position-relative">
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <div className="Speaker">
                                      <figure>
                                        <div className="d-block imgBox">
                                          <img
                                            src={
                                              arrList.fldv_image_url === ""
                                                ? logoNew
                                                : arrList.fldv_image_url
                                            }
                                            className="img-fluid"
                                            alt="img"
                                          />
                                        </div>
                                        <p>{arrList.fldv_speaker_name}</p>
                                      </figure>
                                    </div>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th colSpan="2" className="topic">
                                            {arrList.fldv_title}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th>Date:</th>
                                          <td>
                                            {Moment(
                                              arrList.fldv_select_date
                                            ).format("ddd, D MMM, YYYY")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Time:</th>
                                          <td>
                                            {Moment(arrList.fldt_time).format(
                                              "LT"
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Duration:</th>
                                          <td>{arrList.fldv_duration} hrs</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div className="action">
                                      <div className="readmoreContainer">
                                        <Button
                                          className="readmore"
                                          onClick={(e) =>
                                            this.handleReadMore(
                                              e,
                                              arrList.fldi_id,
                                              arrList.fldv_title
                                                .replace(/ /g, "-")
                                                .toLowerCase()
                                            )
                                          }
                                        >
                                          Read more
                                        </Button>
                                      </div>
                                      <div className="bookmarkContainer">
                                        {arrList.is_bookmark ? (
                                          <IconButton
                                            style={{ color: "#f28b42" }}
                                            className="bookmark"
                                            onClick={(e) =>
                                              this.handleRemoveBookMark(
                                                e,
                                                arrList.fldi_id
                                              )
                                            }
                                          >
                                            <Bookmark />
                                          </IconButton>
                                        ) : (
                                          <IconButton
                                            className="bookmark"
                                            onClick={(e) =>
                                              this.handleAddBookMark(
                                                e,
                                                arrList.fldi_id
                                              )
                                            }
                                          >
                                            <BookmarkBorder />
                                          </IconButton>
                                        )}
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </Card.Body>
                          </Link>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Carousel>
                <IconButton
                  onClick={this.handlePrev}
                  className={singleData ? "singlePrevButton" : "prevButton"}
                >
                  <ArrowLeftIcon />
                </IconButton>
                <IconButton
                  onClick={this.handleNext}
                  className={singleData ? "singleNextButton" : "nextButton"}
                >
                  <ArrowRightIcon />
                </IconButton>
              </Container>
            </section>

            <Dialog
              open={this.state.open}
              onClose={() => this.handleClose()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ paper: classes.dialogPaper }}
            >
              <h2 className="text-center  headBoxRegister text-uppercase">
                Share Feedback
              </h2>
              <DialogContent>
                <WebinarForm
                  webinarId={this.state.id}
                  title={this.state.title}
                  formClose={(e) => this.handleClose(e)}
                />
              </DialogContent>
            </Dialog>

            <Snackbar
              open={this.state.showSnackbar === true}
              autoHideDuration={this.state.snackBarTimer}
              onClose={this.snackBarClose}
            >
              <Alert
                onClose={this.snackBarClose}
                severity={this.state.severityColor}
                elevation={6}
                variant="filled"
              >
                {this.state.snackBarMessage}
              </Alert>
            </Snackbar>

            <Video_dialog
              dialogOpen={this.state.dialogOpen}
              videoUrl={this.state.videoUrl}
              onClose={(e) => this.videoDialogClose(e)}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PastWebinarHome);
