import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { apiURL } from "../utils";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import {
  FormGroup,
  FormControlLabel,
  Snackbar,
  DialogTitle,
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LockOpenIcon from "@material-ui/icons/LockOpen";

const forgotPasswordSchema = yup.object().shape({
  // number: yup.string().required("This field is required."),
  pin: yup.string().required("This field is required."),
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      showPassword: false,
      numberError: false,
      numberErrorMsg: "",
      validnumberError: false,
      validnumberErrorMsg: "",
      authMobile: "",
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
      isOtpSent: false,
      number: "",
      verificationCode: "",
      isOtpVerified: false,
    };
    this.forgotPassword = this.forgotPassword.bind(this);
    this.forgotPasswordDialogClose = this.forgotPasswordDialogClose.bind(this);
    this.check_number = this.check_number.bind(this);
    this.sendOTP = this.sendOTP.bind(this);
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const openPopupParam = searchParams.get("resetloginpin");
    console.log(openPopupParam);
    if (openPopupParam) {
      this.setState({ dialogOpen: true });
    }
  }

  forgotPassword = (e) => {
    this.setState({
      dialogOpen: true,
    });
  };

  forgotPasswordDialogClose = (e) => {
    this.setState({
      dialogOpen: false,
      emailError: false,
      emailErrorMsg: "",
    });
  };

  check_number = (e) => {
    const re = /^[0-9\b]+$/;
    if (re.test(e.target.value)) {
      if (e.target.value) {
        const headers = {
          "content-type": "multipart/form-data",
          Accept: "application/json",
        };
        const body = { contact: e.target.value };
        axios
          .post(apiURL("register/check_number"), body, { headers: headers })
          .then((response) => {
            const resultData = response.data;
            if (resultData.status === false || resultData.status === 0) {
              this.setState({
                isOtpSent: false,
                validnumberError: true,
                validnumberErrorMsg: "This Mobile number is not registered",
              });
            } else {
              this.setState({
                validnumberError: false,
                validnumberErrorMsg: "",
              });
            }
          });
      }
    }
  };
  numberLengthValidate = (e) => {
    if (e.target.value.length !== 10) {
      this.setState({
        numberError: true,
        numberErrorMsg: "Mobile number must be exactly 10 digits",
      });
    } else {
      this.setState({ numberError: false, numberErrorMsg: "" });
    }
  };

  sendOTP = (e) => {
    if (this.state.number === "" || this.state.number === null) {
      return;
    }
    //alert(e.target.value)
    //const number = document.getElementById('number').value;
    //alert(this.state.mobileNumber)

    if (!this.state.numberError && !this.state.validnumberError) {
      this.setState({ isOtpSent: true });
      const headers = {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      };

      const body = {
        number: this.state.number,
        //"number": this.state.mobileNumber,
      };
      axios
        .post(apiURL("register/send_otp"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              authMobile: atob(decodeURIComponent(resultData.result)),
              isOtpSent: true,
              snackBarMessage: "OTP Sent successfully",
              showSnackbar: true,
              severityColor: "success",
              snackBarTimer: 7000,
              // mobileNumber: document.getElementById("number").value,
            });
            localStorage.setItem("otp", resultData.result);
          } else {
            this.setState({ isOtpSent: false });
          }
        });
    } else {
      console.log("number verifed successfully");
      // this.setState({ sendOTPString: false });
    }
  };
  verifyOtp = (values) => {
    if (
      this.state.verificationCode ===
      atob(decodeURIComponent(localStorage.getItem("otp")))
    ) {
      this.setState({
        isOtpVerified: true,
        snackBarMessage: "OTP verified successfully",
        showSnackbar: true,
        severityColor: "success",
        snackBarTimer: 7000,
      });
      localStorage.removeItem("otp");
    } else {
      this.setState({
        isOtpVerified: false,
        showSnackbar: true,
        snackBarMessage: "Please enter valid OTP Number.",
        severityColor: "error",
        snackBarTimer: 7000,
        verificationCode: "",
      });
      this.componentDidMount();
    }
  };
  handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const re = /^\d+$/;
    if (!re.test(keyValue)) {
      e.preventDefault();
    }
  };
  onSubmit = (values) => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = { mobile: this.state.number, new_pin: values.pin };

    axios
      .post(apiURL("register/reset_pin"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            snackBarMessage: resultData.message,
            showSnackbar: true,
            severityColor: "success",
            snackBarTimer: 6000,
            dialogOpen: false,
            numberError: false,
            numberErrorMsg: "",
          });
        } else {
          this.setState({
            snackBarMessage: resultData.message,
            showSnackbar: true,
            severityColor: "error",
            snackBarTimer: 6000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleChangeNumber = (e) => {
    this.setState({
      isOtpSent: false,
      isOtpVerified: false,
      number: e.target.value,
    });
  };
  handleVerification = (e) => {
    this.setState({
      verificationCode: e.target.value,
      isOtpVerified: false,
    });
  };
  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };
  handleClickShowPassword = (event) => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  resendOTP = (e) => {
    //alert('mobile numer'+ this.props.mobileNumber);
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isOtpVerified: false });
    // alert("Verification sent successefully");
    if (this.state.number.length === 10) {
      const headers = {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      };
      const body = {
        number: this.state.number,
      };
      axios
        .post(apiURL("register/send_otp"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              showSnackbar: true,
              snackBarMessage: "New OTP message send to your mobile number.",
              severityColor: "success",
              snackBarTimer: 7000,
            });
            localStorage.setItem("otp", resultData.result);
          }
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          onClick={(e) => this.forgotPassword(e)}
          className="mt-2 pt-md-2 pt-0 col-md-12 col-sm-12 pl-0 passwordBox"
        >
          <h6>
            {" "}
            <LockOpenIcon style={{ fontSize: "1rem" }} /> Reset Login Pin
          </h6>
        </div>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={this.state.dialogOpen}
          onClose={(e) => this.forgotPasswordDialogClose(e)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="boxMain"
        >
          <DialogTitle id="max-width-dialog-title">
            {" "}
            Set New 4 digit Pin
          </DialogTitle>
          <DialogContent className="dialouge">
            <Formik
              initialValues={{
                number: "",
              }}
              validationSchema={forgotPasswordSchema}
              onSubmit={(values) => {
                this.onSubmit(values);
              }}
            >
              {({ errors, handleChange, touched }) => (
                <Form
                  style={{ display: "flex", padding: "0px 25px 25px 25px" }}
                  id="forgot-password"
                >
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="standard-basic"
                        type="text"
                        className="mt-4"
                        name="number"
                        label="Enter Mobile No."
                        onChange={(e) => this.handleChangeNumber(e)}
                        onBlur={(e) => {
                          this.check_number(e);
                          this.numberLengthValidate(e);
                        }}
                        onKeyUp={(e) => this.check_number(e)}
                        onMouseDown={(e) => this.check_number(e)}
                        value={this.state.number}
                        error={
                          (errors.number && touched.number) ||
                          this.state.validnumberError ||
                          this.state.numberError
                        }
                        helperText={
                          errors.number && touched.number
                            ? errors.number
                            : null ||
                              this.state.validnumberErrorMsg ||
                              this.state.numberErrorMsg
                        }
                      />
                    </Grid>
                    {this.state.isOtpSent && (
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="standard-basic"
                          type="text"
                          className="mt-4"
                          name="otp"
                          label="Verifcation Code"
                          onChange={(e) => this.handleVerification(e)}
                          // onBlur={(e) => {
                          //   this.check_number(e);
                          //   this.numberLengthValidate(e);
                          // }}
                          // onKeyUp={(e) => this.check_number(e)}
                          // onMouseDown={(e) => this.check_email(e.target.value)}
                          value={this.state.verificationCode}
                          error={
                            (errors.otp && touched.otp) || this.state.verifyOtp
                          }
                          helperText={
                            errors.otp && touched.otp
                              ? errors.otp
                              : null || this.state.verifyOtp
                          }
                        />
                        <span
                          className="resend-otp"
                          style={{ cursor: "pointer", fontSize: "12px" }}
                          onClick={this.resendOTP}
                        >
                          Resend OTP
                        </span>
                      </Grid>
                    )}
                    {this.state.isOtpVerified && (
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="standard-basic"
                          type={this.state.showPassword ? "text" : "password"}
                          className="mt-4"
                          name="pin"
                          label="Enter New Pin"
                          inputProps={{ maxLength: 4, inputMode: "numeric" }}
                          onChange={handleChange}
                          onKeyPress={this.handleKeyPress}
                          // onBlur={(e) => {
                          //   this.check_number(e);
                          //   this.numberLengthValidate(e);
                          // }}
                          // onKeyUp={(e) => this.check_number(e)}
                          // onMouseDown={(e) => this.check_email(e.target.value)}
                          value={this.state.login_pin}
                          error={errors.login_pin && touched.login_pin}
                          helperText={
                            errors.login_pin && touched.login_pin
                              ? errors.otp
                              : null
                          }
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={(e) =>
                                    this.handleClickShowPassword(e)
                                  }
                                  onMouseDown={(e) =>
                                    this.handleMouseDownPassword(e)
                                  }
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                      {this.state.isOtpSent && this.state.isOtpVerified && (
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="mt-4 text-capitalize"
                        >
                          Set Pin
                        </Button>
                      )}
                      {!this.state.isOtpSent && !this.state.isOtpVerified && (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="mt-4 text-capitalize"
                          onClick={(e) => this.sendOTP(e)}
                        >
                          Send OTP
                        </Button>
                      )}
                      {this.state.isOtpSent && !this.state.isOtpVerified && (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="mt-4 text-capitalize"
                          onClick={(e) => this.verifyOtp(e)}
                        >
                          Verify
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        <Snackbar
          open={this.state.showSnackbar === true}
          autoHideDuration={this.state.snackBarTimer}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
