import React, { Fragment, useEffect, useState } from "react";
import Appbar from "../Appbar";
import {
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import EditForm from "./EditProfile";
import ProfileInfo from "./ProfileInfo";
import Security from "./Security";
import "../../style/Profile.scss";
import Footer from "../Footer";
import Helmet from "react-helmet";
import {
  getBoard,
  getCity,
  getContact,
  getEmail,
  getGrades,
  getLastName,
  getName,
  getPincode,
  getRole,
  getSubjects,
  getUsername,
  getSchCollName,
} from "../../utils";
import { makeStyles } from "@material-ui/core";
import { Row, Col } from "bootstrap-4-react";
const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));
const Profile = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const orgGrades = getGrades()
    ? getGrades()
        .split(",")
        .map((grade) => grade.trim())
    : [];
  const orgSubjects = getSubjects()
    ? getSubjects()
        .split(",")
        .map((sub) => sub.trim())
    : [];
  const [userData, setUserData] = useState({
    name: getUsername(),
    firstname: getName(),
    lastname: getLastName(),
    role: getRole(),
    contact: getContact(),
    email: getEmail(),
    city: getCity(),
    schcollname: getSchCollName(),
    pincode: getPincode(),
    board: getBoard(),
    grades: orgGrades,
    subjects: orgSubjects,
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);
  const handleEditClick = () => {
    setEditing(true);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const handleCancelEdit = () => {
    setEditing(false);
    setLoading(false);
  };
  const updateUserData = (newData) => {
    console.log("updateuser", newData);
    setUserData(newData);
    setEditing(false);
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 2000);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Profile</title>

        <meta charset="utf-8" />
        <link rel="icon" href="/navdisha-favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#000000" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta name="robots" content="Profile" />
        <link rel="canonical" href={window.location.href} />

        <meta name="description" content="Profile" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Appbar />
      <section className="BannerBox profileBanner">
        <div className="sixteen-nine">
          <div className="content text-center text-white invisible">
            <h2>Past Training Schedule</h2>
            <p>PAST TRAINING SCHEDULE TO FEED YOUR BRAIN</p>
          </div>
        </div>
      </section>
      <Container maxWidth="lg" className="mainProfile">
        <Row className="userNameRow">
          <Col col="12 col-lg-6 col-xl-4" className="userName">
            <Typography variant="h5">
              Welcome, {userData.firstname} {userData.lastname}
            </Typography>
          </Col>
        </Row>
        <Row className="editButtonRow d-flex justify-content-end">
          {!editing && (
            <Button className="editButton" onClick={handleEditClick}>
              Edit Information
            </Button>
          )}
        </Row>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : editing ? (
          <EditForm
            onCancelClick={handleCancelEdit}
            userData={userData}
            updateUserData={updateUserData}
          />
        ) : (
          <ProfileInfo userData={userData} />
        )}

        <Security />
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Profile;
