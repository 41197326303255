import React, { Fragment, useState, useEffect } from "react";
import SanitizedHTML from "react-sanitized-html";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Card } from "bootstrap-4-react";
import { Container, FormControlLabel, Radio, Button } from "@material-ui/core";
import {
  RadioButtonCheckedOutlined,
  RadioButtonUnchecked,
} from "@material-ui/icons";
const SubjectFaq = ({ faqs }) => {
  const [openAns, setOpenAns] = useState(null);
  useEffect(() => {
    if (faqs.length > 0) {
      setOpenAns(faqs[0].fldi_id);
    }
  }, [faqs]);
  const handleOpenAns = (id) => {
    setOpenAns((prevOpenAns) => (prevOpenAns === id ? null : id));
  };
  return (
    <Fragment>
      <section className="faqSection">
        <Container maxWidth="lg" className="container">
          <div className="faqContent">
            <div className="questionList">
              <Card className="questionCard">
                <ul>
                  {faqs.map((list) => (
                    <li
                      key={list.id}
                      onClick={() => handleOpenAns(list.fldi_id)}
                      className={openAns === list.fldi_id ? "selected" : ""}
                    >
                      <div className="labelList">
                        <div className="listIcon">
                          {openAns === list.fldi_id ? (
                            <RadioButtonCheckedOutlined />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
                        </div>
                        <div className="questionLabel">{list.fldv_title}</div>
                        {/* <FormControlLabel
                        className="formControlLabel"
                        control={
                          <Radio
                            checked={openAns === list.fldi_id}
                            className="radio"
                          />
                        }
                        label=
                      /> */}
                      </div>
                      <ArrowRightIcon />
                    </li>
                  ))}
                </ul>
              </Card>
            </div>
            <div className="answerList">
              {faqs.map(
                (ans) =>
                  openAns === ans.fldi_id && (
                    <Card key={ans.fldi_id} className="answerCard">
                      <div className="faqAnswer">
                        <div className="question">{ans.fldv_title}</div>
                        <div className="answer">
                          {" "}
                          <SanitizedHTML
                            allowedAttributes={false}
                            allowedTags={false}
                            html={ans.fldt_description}
                          />
                        </div>
                      </div>
                    </Card>
                  )
              )}
            </div>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default SubjectFaq;
