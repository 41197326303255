import React, { Component } from "react";
import { Row, Col, Card } from "bootstrap-4-react";
import { IconButton, Button, Container } from "@material-ui/core";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import Meta from "../meta/Meta";
import "../style/HomeEvents.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Moment from "moment";
class ArticlesHome extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      per: window.innerWidth <= 1000 ? 1 : 2,

      categoryID: null,
      noData: "",
      selectedTab: 0,
      loading: true,
      selectedItem: 0,
      articleData: [],
      isMobile: false,
    };
    // this.carouselRef = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
    this.articleListData();
  }
  //   componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.articleData !== this.state.articleData) {
  //       this.setState({ articleData: this.state.articleData });
  //     }
  //   }
  componentWillUnmount() {
    this._isMounted = false;
  }
  articleListData = () => {
    this.setState({ loading: true });

    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      id: "",
      category_id: "",
      // user_id:getID()
    };
    axios
      .post(apiURL("article/get_article_all"), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              articleData: resultData.result.slice(0, 4),
              noData: "",
              loading: false,
            });
          } else {
            this.setState({
              noData: "",
              articleData: [],
              loading: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  redirectArticle = (e, id, title, category, category_id) => {
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      this.props.history.push(
        "/article/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
        { category }
      );
    }
  };

  handleAddBookMark = (e, id) => {
    e.stopPropagation();
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      const headers = {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "articles",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const index = this.state.articleData.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...this.state.articleData];
              updatedEventsList[index].is_bookmark = true;
              this.setState({
                articleData: updatedEventsList,
              });
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleRemoveBookMark = (e, id) => {
    e.stopPropagation();
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "articles",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.articleData.map((event) => {
            if (event.fldi_id === id) {
              return {
                ...event,
                is_bookmark: false,
              };
            }
            return event;
          });
          this.setState({
            articleData: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handlePrev = () => {
    this.setState((prevState) => ({
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  handleNext = () => {
    const { selectedItem, per, articleData } = this.state;
    const totalPages = Math.ceil(articleData.length / per);

    this.setState((prevState) => ({
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };
  render() {
    const { id } = this.props;
    const { selectedItem, per, articleData, isMobile } = this.state;

    const displayData = isMobile
      ? articleData.slice(selectedItem, selectedItem + 1)
      : articleData.slice(selectedItem * 2, selectedItem * 2 + 2);
    const singleData = displayData.length === 1;
    return (
      <React.Fragment>
        {articleData.length !== 0 && (
          <div>
            {/* <section className="circularSection"> */}

            <section className="articleGrHome">
              <Container maxWidth="lg" className="container">
                <Carousel
                  ref={this.carouselRef}
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={false}
                  showIndicators={false}
                  showStatus={false}
                  selectedItem={selectedItem}
                  onChange={(index) => this.setState({ selectedItem: index })}
                >
                  <Row className="articleGrMainHome">
                    {displayData.map((arrArticle, index) => {
                      return (
                        <Col
                          key={index}
                          col="12 col-lg-6 col-xl-4"
                          className="mx-auto custom-col-spacing mt-4"
                        >
                          <Card
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              this.redirectArticle(
                                e,
                                arrArticle.fldi_id,
                                arrArticle.fldv_title,

                                arrArticle.fldv_category_name,
                                arrArticle.fldi_category_id
                              )
                            }
                          >
                            <Card.Body>
                              <figure>
                                <div className="d-block mainImage">
                                  <img
                                    src={arrArticle.fldv_image_url}
                                    className="img-fluid"
                                    alt={arrArticle.fldv_title}
                                  />
                                </div>
                              </figure>
                              <div className="content">
                                <div className="topic">
                                  <h4>{arrArticle.fldv_title}</h4>
                                </div>
                                <div className="date">
                                  <h4>{arrArticle.fldv_author_name}</h4>
                                </div>
                              </div>
                            </Card.Body>

                            <div className="action">
                              <div>
                                <Button
                                  variant="contained"
                                  size="small"
                                  className="readmore"
                                  onClick={(e) =>
                                    this.redirectArticle(
                                      e,
                                      arrArticle.fldi_id,
                                      arrArticle.fldv_title,
                                      arrArticle.fldv_category_name,
                                      arrArticle.fldi_category_id
                                    )
                                  }
                                >
                                  Read More
                                </Button>
                              </div>
                              <div>
                                {arrArticle.is_bookmark ? (
                                  <IconButton
                                    style={{ color: "orange" }}
                                    className="bookmark"
                                    onClick={(e) =>
                                      this.handleRemoveBookMark(
                                        e,
                                        arrArticle.fldi_id
                                      )
                                    }
                                  >
                                    <Bookmark />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    className="bookmark"
                                    onClick={(e) =>
                                      this.handleAddBookMark(
                                        e,
                                        arrArticle.fldi_id
                                      )
                                    }
                                  >
                                    <BookmarkBorder />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Carousel>
                <IconButton
                  onClick={this.handlePrev}
                  className={singleData ? "singlePrevButton" : "prevButton"}
                >
                  <ArrowLeftIcon />
                </IconButton>
                <IconButton
                  onClick={this.handleNext}
                  className={singleData ? "singleNextButton" : "nextButton"}
                >
                  <ArrowRightIcon />
                </IconButton>
              </Container>
            </section>
            {/* </Container>
          </section> */}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ArticlesHome;
