import React, { Component } from "react";
import { Row, Col, Container, Card } from "bootstrap-4-react";
import { IconButton, Button } from "@material-ui/core";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import Meta from "../meta/Meta";
import "../style/GovtCircular.scss";
import Moment from "moment";
class GovtCircular extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      per: 4,
      newsData: [],
      categoryID: null,
      noData: "",
      selectedTab: 0,
      loading: true,
    };
    this.circularListData = this.circularListData.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.circularListData();

    this.scrollListener = window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.newsData !== this.state.newsData) {
      this.extractUniqueYears();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  circularListData = () => {
    this.setState({ loading: true });
    const { per, page, newsData } = this.state;
    const headers = {
      "Content-type": "application/json",
    };
    const body = { id: "", user_id: getID(), board: this.props.board };
    axios
      .post(
        apiURL("news/get_all_news?per_page=" + per + "&page=" + page),
        body,
        { headers: headers }
      )
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const updatedNewsList = [...newsData, ...resultData.result];
            this.setState({
              newsData: updatedNewsList,
              scrolling: false,
            });
            this.setState({ loading: false, scrolling: true });
          } else {
            this.setState({ noData: "", loading: false, scrolling: false });
          }
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  loadMore = () => {
    if (this._isMounted) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          scrolling: false,
        }),
        this.circularListData
      );
    }
  };

  handleScroll = () => {
    if (this._isMounted) {
      const lastLi = document.querySelector(".mainCircularSection");
      if (lastLi !== null) {
        const lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        const pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastLiOffset) {
          if (this.state.scrolling) {
            this.loadMore();
          }
        }
      }
    }
  };
  extractUniqueYears = () => {
    const years = [
      ...new Set(
        this.state.newsData.map((event) =>
          Moment(event.fldv_selected_date).format("YYYY")
        )
      ),
    ];
    console.log(years);
    this.props.onDataUpdate(years);
  };
  redirectArticle = (e, id, title, category) => {
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      this.props.history.push(
        "/news/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
        { category }
      );
    }
  };

  handleAddBookMark = (e, id) => {
    e.stopPropagation();
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      const headers = {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "news",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const index = this.state.newsData.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...this.state.newsData];
              updatedEventsList[index].is_bookmark = true;
              this.setState({
                newsData: updatedEventsList,
              });
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleRemoveBookMark = (e, id) => {
    e.stopPropagation();
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "news",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.newsData.map((event) => {
            if (event.fldi_id === id) {
              return {
                ...event,
                is_bookmark: false,
              };
            }
            return event;
          });
          this.setState({
            newsData: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { year, language } = this.props;
    const filteredData = year
      ? this.state.newsData.filter(
          (event) =>
            Moment(event.fldv_select_date).format("YYYY") === year &&
            (language === "" || event.fldv_language === language)
        )
      : this.state.newsData.filter(
          (event) => language === "" || event.fldv_language === language
        );
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />

        <div>
          {/* <section className="circularSection"> */}
          {/* <Container> */}
          <Row className="circularMain">
            {filteredData.map((arrNews, index) => {
              return (
                <Col
                  key={index}
                  col="12 col-lg-6 col-xl-4"
                  className="mx-auto custom-col-spacing mt-4"
                >
                  <Card
                    style={{ cursor: "pointer" }}
                    onClick={(e) =>
                      this.redirectArticle(
                        e,
                        arrNews.fldi_id,
                        arrNews.fldv_title,
                        this.props.category_name
                      )
                    }
                  >
                    <Card.Body>
                      <figure>
                        <div className="d-block mainImage">
                          <img
                            src={arrNews.fldv_image_url}
                            className="img-fluid"
                            alt={arrNews.fldv_title}
                          />
                        </div>
                      </figure>
                      <div className="content">
                        <div className="topic">
                          <h4>{arrNews.fldv_title}</h4>
                        </div>
                        <div className="date">
                          <h4>
                            {" "}
                            {Moment(arrNews.fldv_selected_date).format(
                              "D MMM, yyyy"
                            )}
                          </h4>
                        </div>
                      </div>
                    </Card.Body>

                    <div className="actionGrMain">
                      <Button
                        variant="contained"
                        size="small"
                        className="readmore"
                        onClick={(e) =>
                          this.redirectArticle(
                            e,
                            arrNews.fldi_id,
                            arrNews.fldv_title
                          )
                        }
                      >
                        Read More
                      </Button>
                      {arrNews.is_bookmark ? (
                        <IconButton
                          style={{ color: "orange" }}
                          className="bookmark"
                          onClick={(e) =>
                            this.handleRemoveBookMark(e, arrNews.fldi_id)
                          }
                        >
                          <Bookmark />
                        </IconButton>
                      ) : (
                        <IconButton
                          className="bookmark"
                          onClick={(e) =>
                            this.handleAddBookMark(e, arrNews.fldi_id)
                          }
                        >
                          <BookmarkBorder />
                        </IconButton>
                      )}
                    </div>
                  </Card>
                </Col>
              );
            })}

            <Col sm="12" className="mt-4 text-center">
              <h1>{this.state.noData}</h1>
            </Col>
          </Row>
          <div
            ref={(loadingRef) => (this.loadingRef = loadingRef)}
            style={{ height: "30px", margin: "10px" }}
          >
            <span
              style={{
                display: this.state.loading ? "block" : "none",
                textAlign: "center",
              }}
            >
              Loading...
            </span>
            <span>{this.state.scrolling ? this.state.noData : ""}</span>
          </div>
          {/* </Container>
          </section> */}
        </div>
      </React.Fragment>
    );
  }
}

export default GovtCircular;
