import React, { Component } from "react";
import Navmenu from "../components/Navmenu";
import Appbar from "../components/Appbar";
import CareerForm from "../training/CareerForm";
import "../style/training.scss";

import { Row, Col, Card, Carousel, List } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import { Container, IconButton } from "@material-ui/core";
import groupwork from "../images/ps1.jpg";
import phadke from "../images/ps2.jpg";
// import group from '../images/satara-group.jpg';
import inauguaration from "../images/ps3.jpg";
import mumbai from "../images/ps4.jpg";
import nashik from "../images/ps5.jpg";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import dateImage from "../images/date.png";
import timeImage from "../images/time.png";
import durationImage from "../images/duration.png";
import priceImage from "../images/price.png";
import ReactPlayer from "react-player/lazy";
import Rating from "../components/Rating";
import {
  apiURL,
  isLogin,
  getID,
  getName,
  getEmail,
  getContact,
} from "../utils";
import axios from "axios";
import Moment from "moment";
import SanitizedHTML from "react-sanitized-html";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Sign from "../login/Sign";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Meta from "../meta/Meta";
import {
  ArrowBack,
  Bookmark,
  BookmarkBorder,
  LocationOn,
} from "@material-ui/icons";
import ShareDialog from "../components/ShareDialog";
import ShareNews from "../govt-circular-gr/ShareNews";
import OtherTraining from "./OtherTraining";
const styles = (theme) => ({
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh",
    borderRadius: "25px",
    maxWidth: "42.75rem",
  },
  speakerImage: {
    width: "32%",
    height: "auto",
    borderRadius: "50%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  buttonContainer: {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    paddingLeft: theme.spacing(1), // Add padding to left to ensure space
    paddingRight: theme.spacing(1), // Add padding to right to ensure space
  },
  button: {
    margin: theme.spacing(1),
  },
});
class Training extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      trainingSchedule: [],
      open: false,
      showComponent: false,
      alreadyRegister: false,
      alreadyRegister: false,
      userId: "",
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
      title: "",
      joinNow: "",
      name: getName(),
      email: getEmail(),
      contact: getContact(),
      showShareDialog: false,
      is_bookmark: false,
      isMobile: false,
      imageGallery: [],
    };
    this.trainingSchedule = this.trainingSchedule.bind(this);
    this.registerNow = this.registerNow.bind(this);
    this.checkUserRegister = this.checkUserRegister.bind(this);
  }
  handleBack = () => {
    this.props.history.push("/past-trainings");
  };
  handleClickOpen = () => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      // this.setState({ open: true })
      //this.registerNow();
      this.setState({ registerDialog: true });
    }
  };

  registerTrainingClose = () => {
    this.setState({ registerDialog: false, id: "", title: "" });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    this.trainingSchedule();
    this.checkUserRegister();

    this.setState({ isMobile: window.innerWidth <= 1000 });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.trainingId !== this.props.match.params.trainingId
    ) {
      window.scrollTo(0, 0);
      this.trainingSchedule();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };
  handleShareClick = () => {
    this.setState({ showShareDialog: true });
  };

  handleCloseShareDialog = () => {
    this.setState({ showShareDialog: false });
  };
  checkUserRegister = () => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      training_id: this.props.match.params.trainingId,
      user_id: getID(),
    };
    axios
      .post(apiURL("join_training_user/check_join_user"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({ alreadyRegister: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  trainingSchedule = () => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    axios
      .get(
        apiURL(
          "training_schedule/get_training_schedule_by_id/" +
            this.props.match.params.trainingId +
            "/" +
            getID()
        ),
        { headers: headers }
      )
      .then((response) => {
        const resultData = response.data;
        // alert(resultData.message);
        if (resultData.status === true) {
          console.log("hiinif");
          const trainingSchedule = resultData.result;
          const is_bookmark = trainingSchedule.is_bookmark;

          this.setState({
            trainingSchedule,
            is_bookmark,
            imageGallery:
              trainingSchedule.fldv_file_urls !== "" &&
              trainingSchedule.fldv_file_urls !== "null"
                ? JSON.parse(trainingSchedule.fldv_file_urls)
                : [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkJoinNow = () => {
    this.trainingSchedule();
    this.setState({
      showComponent: false,
      registerDialog: true,
      name: getName(),
      email: getEmail(),
      contact: getContact(),
    });
  };

  registerNow = () => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      training_id: this.props.match.params.trainingId,
      user_id: getID(),
    };
    axios
      .post(apiURL("join_training_user/add"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({ joinNow: true });
          this.componentDidMount();

          this.setState({
            registerDialog: false,
            name: "",
            email: "",
            contact: "",
            showSnackbar: true,
            snackBarMessage:
              "Thank you for getting in touch! We appreciate you contacting us....",
            severityColor: "success",
            snackBarTimer: "3000",
          });
        } else {
          this.setState({ noData: "Coming Soon" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleAddBookMark = (id) => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_training",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        //alert(resultData.message);
        if (resultData.success === true || resultData.success === 1) {
          this.setState({
            is_bookmark: true,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleRemoveBookMark = (id) => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_training",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            is_bookmark: false,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { classes } = this.props;
    const { showShareDialog, isMobile } = this.state;

    const startMoment = Moment(
      new Date(this.state.trainingSchedule.fldv_from_time)
    );
    const endMoment = Moment(
      new Date(this.state.trainingSchedule.fldv_to_time)
    );
    const differenceInHours = endMoment.diff(startMoment, "hours", true);

    return (
      <React.Fragment>
        <Meta
          tableName={"training_schedule"}
          objectId={this.props.match.params.trainingId}
          forPage={"inner_page"}
        />
        {this.state.showComponent ? (
          <Sign
            ref={this._sign}
            pageProp={this.props}
            checkJoinNow={(e) => this.checkJoinNow(e)}
          />
        ) : (
          <div>
            <Appbar />
            {/* <div className="NavBar   pb-0 sticky-top ">
            <Navmenu />
         </div> */}
            <div className="sticky-top sign-light">
              <Navmenu />
            </div>
            <section className="BannerBox TrainingBox">
              <div className="sixteen-nine">
                <div className="content text-center text-white invisible">
                  <h2>Past Training Schedule</h2>
                  <p>PAST TRAINING SCHEDULE TO FEED YOUR BRAIN</p>
                </div>
              </div>
            </section>
            <section className="pastTrainMain">
              <Container maxWidth="lg">
                <h1>{this.state.trainingSchedule.fldv_title}</h1>
                <List inline>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                </List>
                <Row className="d-flex">
                  <Col
                    lg="6"
                    md="12"
                    sm="12"
                    className="order-lg-1 order-md-1 order-1"
                  >
                    <h5 className="speakerName">
                      {this.state.trainingSchedule.fldv_speaker_name}
                    </h5>
                    {/* <h5>{this.state.points.map((point) => point.point)}</h5> */}
                    <div className="fixed-content">
                      <SanitizedHTML
                        allowedAttributes={false}
                        allowedTags={false}
                        html={this.state.trainingSchedule.fldv_description}
                      />
                    </div>
                  </Col>
                  <Col
                    lg="6"
                    md="12"
                    sm="12"
                    className="webVideo order-lg-2 order-md-2 order-2"
                  >
                    <div className="player-wrapper">
                      <img
                        src={this.state.trainingSchedule.fldv_event_image}
                        className="react-player"
                        controls="true"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg="6"
                    md="12"
                    sm="12"
                    className="order-lg-1 order-md-1 order-1"
                  >
                    <table className="table">
                      {/* <thead>
                                    <tr>
                                      <th>Key</th>
                                      <th>Value</th>
                                    </tr>
                                  </thead> */}
                      <tbody>
                        <tr>
                          <td>
                            <img src={dateImage} className="img-fluid" /> :{" "}
                            {Moment(
                              this.state.trainingSchedule.fldv_select_date
                            ).format("ddd, MMMM Do, YYYY")}
                          </td>
                          <td>
                            <img src={timeImage} className="img-fluid" /> :{" "}
                            {Moment(
                              this.state.trainingSchedule.fldt_time
                            ).format("hh:mmA")}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <img
                              src={durationImage}
                              className="img-fluid"
                            /> : {this.state.trainingSchedule.fldv_duration}{" "}
                            Hours
                          </td>

                          <td>
                            {" "}
                            <LocationOn color="primary" />:{" "}
                            {this.state.trainingSchedule.fldv_venue_location !==
                              "" &&
                              this.state.trainingSchedule.fldv_venue_location}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Container>
            </section>
            {this.state.imageGallery.length !== 0 ? (
              <section>
                <Container maxWidth="md">
                  <Row className="pastrainingGallery mt-5">
                    <Col col="lg-8 md-8 sm-10 " className="TestBox">
                      <Carousel id="carouselExampleControls">
                        <Carousel.Inner>
                          {this.state.imageGallery.map((image, index) => (
                            <Carousel.Item key={index} active={index === 0}>
                              <Card>
                                <img
                                  src={image}
                                  className="img-fluid w-100 carousel-image"
                                  alt={`Slide ${index}`}
                                />
                              </Card>
                            </Carousel.Item>
                          ))}
                        </Carousel.Inner>
                        <Carousel.Prev href="#carouselExampleControls">
                          <ArrowLeftIcon className="ArrowLeft" />
                        </Carousel.Prev>
                        <Carousel.Next href="#carouselExampleControls">
                          <ArrowRightIcon className="ArrowRight" />
                        </Carousel.Next>
                      </Carousel>
                    </Col>
                  </Row>
                </Container>
              </section>
            ) : (
              ""
            )}
            {/* <Row className="justify-content-between align-items-center mt-3">
                  <Col md={6} xs={12}>
                    <Button>Back</Button>
                    <Button>Bookmark</Button>
                  </Col>
                  <Col
                    md={6}
                    xs={12}
                    className="d-flex justify-content-end mt-3 mt-md-0"
                  >
                    <Button>Share</Button>
                  </Col>
                </Row> */}

            <section className="buttonTrContainer">
              <Container className="mt-5" maxWidth="lg">
                <div className="actionTraining">
                  {this.state.is_bookmark === true ? (
                    <IconButton
                      className="bookmark"
                      style={{
                        color: "orange",
                        border: "3px solid #064e89",
                      }}
                      onClick={() =>
                        this.handleRemoveBookMark(
                          this.state.trainingSchedule.fldi_id
                        )
                      }
                    >
                      <Bookmark />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="bookmark"
                      style={{ border: "3px solid #064e89" }}
                      onClick={() =>
                        this.handleAddBookMark(
                          this.state.trainingSchedule.fldi_id
                        )
                      }
                    >
                      <BookmarkBorder />
                    </IconButton>
                  )}
                  <div className="rating">
                    <Rating
                      trainId={this.state.trainingSchedule.fldi_id}
                      userId={getID()}
                      rating={this.state.trainingSchedule.rating}
                      color="gold"
                    />
                  </div>
                </div>
                <div className="share">
                  <ShareNews
                    webinarId={this.state.trainingSchedule.fldi_id}
                    shareUrl={
                      this.state.trainingSchedule.fldv_video_url === ""
                        ? "https://navdisha.navneet.com"
                        : this.state.trainingSchedule.fldv_video_url
                    }
                    title={this.state.trainingSchedule.fldv_title}
                    date={Moment(
                      this.state.trainingSchedule.fldv_select_date
                    ).format("dddd, D MMM, YYYY")}
                    time={Moment(this.state.trainingSchedule.fldt_time).format(
                      "hh:mmA"
                    )}
                  />
                </div>

                <Row>
                  <div className="pastBack">
                    <Button
                      // variant="contained"
                      onClick={this.handleBack}
                      // className="back ml-3"
                    >
                      Back
                    </Button>
                  </div>
                </Row>
              </Container>
            </section>
            {this.state.trainingSchedule.fldi_id !== "" && (
              <OtherTraining
                id={this.state.trainingSchedule.fldi_id}
                history={this.props.history}
              />
            )}
            <Footer />
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(Training);
