import React, { Component, Suspense } from "react";
import Appbar from "../components/Appbar";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
//import {Paper} from '@material-ui/core';
//import Button from '@material-ui/core/Button';
import Carouselslide from "../home/Carouselslide";
import Navmenu from "../components/Navmenu";
import Footer from "../components/Footer";
import Speaker from "./Speaker";
import Video from "./Video";
import Blog from "./Blog";
import Student from "../home/Student";
import Welcome from "../home/Welcome";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import "../style/Home.scss";
import Bootstrap, { Carousel } from "bootstrap-4-react";
import axios from "axios";
import { apiURL, googleAnalytics, passwordStatus } from "../utils";
import SanitizedHTML from "react-sanitized-html";
import RecentTopics from "./RecentTopics";
import Meta from "../meta/Meta";
import { askForPermissioToReceiveNotifications } from "../push-notification";
import { Snackbar, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Helmet } from "react-helmet";

import HomeEvents from "./HomeEvents";
import WhatsNew from "./WhatsNew";

class Home extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      testimonialsData: [],
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
    };
    this.getTestimonialsData = this.getTestimonialsData.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.getTestimonialsData();
    /*if(window.location.search)
    {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const authEmail = urlParams.get('auth')
        //alert(atob(decodeURIComponent(authEmail)));
        //const decodeAuthEmail = atob(decodeURIComponent(authEmail));
        this.authenticateEmail(authEmail); 
        
    }*/
  }

  /*authenticateEmail = (email) => {
    const headers = { 
    'Content-type': 'application/json'
    }
    const body = { 
        "email": email, 
    }
    axios.post(apiURL('authenticate'), body, {headers: headers})
    .then(response => { 
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        { 
          this.setState({ 
            snackBarMessage: "Email address verified successfully. Please login",
            showSnackbar: true , 
            severityColor:'success', 
            snackBarTimer: 3000
          });
          this.props.history.push({ pathname: '/sign',  state: { 
            value: 0,
            snackBarMessage: "Email address verified successfully. Please login",
            showSnackbar: true , 
            severityColor:'success', 
            snackBarTimer: 3000
          } })
        } 
    })
   }*/

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  getTestimonialsData = () => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .post(apiURL("testimonials/get_testimonials"), { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              testimonialsData: resultData.result,
            });
          }
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={1} forPage={"main_page"} />
        <Helmet>
          <title>Home</title>

          <meta charset="utf-8" />
          <link rel="icon" href="/navdisha-favicon.png" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="theme-color" content="#000000" />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
          <meta name="robots" content="Home" />
          <link rel="canonical" href={window.location.href} />

          <meta name="description" content="Home" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <Appbar />
        <div className="sticky-top sign-light">
          <Navmenu />
        </div>
        <Carouselslide propsData={this.props} />
        {/* <Navmenu />  */}

        <div className="WelcomeBox d-none">
          <Welcome propsData={this.props} />
        </div>
        <div className="clearfix"></div>

        <Student />

        {/* <section className="videoBox mt-md-5 mt-4">
          <Video />
        </section> */}
        <HomeEvents history={this.props.history} />
        <WhatsNew history={this.props.history} />
        {/* <PastWebinarHome /> 
        {/**/}

        {this.state.testimonialsData.length !== 0 && (
          <section>
            <Container>
              <Row className="Testimonial mt-5">
                <Col col="sm-12" className="text-center">
                  <h2 className="mb-0">Testimonials</h2>
                </Col>

                <Col col="lg-5 md-8 sm-10 " className="mx-auto TestBox">
                  <Carousel w="100" id="carouselExampleControls">
                    <Carousel.Inner>
                      {this.state.testimonialsData.map((arrList, index) => {
                        return (
                          <Carousel.Item active={index === 0} key={index}>
                            <Card>
                              <img
                                src={arrList.fldv_image_url}
                                className="w-25 mx-auto"
                                alt={arrList.fldv_name}
                              />
                              <Card.Body className="text-center">
                                <Card.Title>{arrList.fldv_name}</Card.Title>
                                {/* <Card.Text> */}
                                <SanitizedHTML
                                  html={arrList.fldt_description}
                                />
                                {/* </Card.Text> */}
                              </Card.Body>
                            </Card>
                          </Carousel.Item>
                        );
                      })}
                      {/*<Carousel.Item active>  
                          <Card>
                            <img src="../images/speaker-1.png" className="w-25 mx-auto" alt="" />
                            <Card.Body className="text-center" >
                                <Card.Title>Soares Beena</Card.Title>  
                                <Card.Text>
                                  An excellent way to equip teachers, students and parents as well.I congratulate Navneet Foundation to have taken this decision to enlighten all.Thank you
                                </Card.Text>
                            </Card.Body>
                          </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                          <Card>
                            <img src="../images/speaker-1.png" className="w-25 mx-auto" alt="" />
                            <Card.Body className="text-center" >
                              <Card.Title>Anil Badekar</Card.Title> 
                                <Card.Text>
                                    शिक्षकांना असलेल्या अनेक प्रश्नांची उत्तरे आपल्या या मार्गदर्शनातून मिळाली.खूप छान मार्गदर्शन.धन्यवाद !!!नवनीत foundation 
                                </Card.Text>
                              </Card.Body>
                          </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                          <Card>
                            <img src="../images/speaker-1.png" className="w-25 mx-auto" alt="" />
                            <Card.Body className="text-center" >
                              <Card.Title>Anil Durge</Card.Title> 
                                <Card.Text>
                                  Today's topic is a burning issue in the educational field.As usual, Navneet has taken initiative to carry out discussion on this topic.The discussion was very useful & informative.It will be better to students & their parents, if such topics are publicly discussed.
                                </Card.Text>
                            </Card.Body>
                          </Card>
                        </Carousel.Item>
                        <Carousel.Item>
                          <Card>
                            <img src="../images/speaker-1.png" className="w-25 mx-auto" alt="" />
                            <Card.Body className="text-center" >
                              <Card.Title>Anil Durge</Card.Title> 
                              <Card.Text>
                                अतिशय सुंदर मार्गदर्शन नवनीत ने या वेबिनार च्या माध्यमातून उपलब्ध करून दिले ,सर्वांचे धन्यवाद!
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                          <Card>
                            <img src="../images/speaker-1.png" className="w-25 mx-auto" alt="" />
                            <Card.Body className="text-center" >
                              <Card.Title>Vijaykumar Kodgule</Card.Title> 
                              <Card.Text>
                                  शिक्षकास अपेक्षित माहिती मिळाली, धन्यवाद 
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Carousel.Item>
                        <Carousel.Item>
                          <Card>
                            <img src="../images/speaker-1.png" className="w-25 mx-auto" alt="" />
                            <Card.Body className="text-center" >
                              <Card.Title>Rohini Khairnar</Card.Title>  
                              <Card.Text>
                                Proper guidance given through all experts by Navneet Foundation. Thank you Navneet for being there for all the teachers as always.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Carousel.Item>*/}
                    </Carousel.Inner>
                    <Carousel.Prev href="#carouselExampleControls">
                      <ArrowLeftIcon className="ArrowLeft" />
                    </Carousel.Prev>
                    <Carousel.Next href="#carouselExampleControls">
                      <ArrowRightIcon className="ArrowRight" />
                    </Carousel.Next>
                  </Carousel>
                </Col>
              </Row>
              {/*{passwordStatus() === "1"?<ResetPassword />:""}*/}
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={this.state.showSnackbar === true}
                autoHideDuration={this.state.snackBarTimer}
                onClose={this.snackBarClose}
              >
                <Alert
                  onClose={this.snackBarClose}
                  severity={this.state.severityColor}
                  elevation={6}
                  variant="filled"
                >
                  {this.state.snackBarMessage}
                </Alert>
              </Snackbar>
            </Container>
          </section>
        )}
        <Footer />
      </React.Fragment>
    );
  }
}

/*function Item(props)
{
    return (
        <Paper>   
           
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>
 
            <Button className="CheckButton">
                Check it out!
            </Button>
        </Paper>
    )
}*/

export default Home;
