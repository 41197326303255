import React, { Component, createRef } from "react";
//import { Row, Col,  } from 'bootstrap-4-react';
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
//import PasswordField from 'material-ui-password-field';
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import * as yup from "yup";

import {
  FormGroup,
  FormControlLabel,
  Snackbar,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
// import SearchIcon from '@material-ui/icons/Search';
import { apiURL, login, getID } from "../utils";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
// import { Link, useHistory, withRouter } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import firebase from "../firebase";
import "firebase/messaging";
import Helmet from "react-helmet";

const FormControl = require("@material-ui/core/FormControl").default;
const FormHelperText = require("@material-ui/core/FormHelperText").default;
//  const InputLabel     = require('@material-ui/core/InputLabel').default;

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
      width: "100%",
    },
  },
});

const LoginSchema = yup.object().shape({
  number: yup.string().required("This field is required."),
  // login_pin: yup
  //   .array()
  //   .of(yup.string().required("This field is required."))
  //   .min(4, "Please fill all four boxes."),
  //agree: yup.string().required('Please Check, I agree to the Terms & Conditions'),
});

class SignIn extends Component {
  //state = { checked: false }
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      checkBoxError: false,
      checkBoxErrorMsg: "",
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      reload: false,
      isNumberVerified: false,
      isNumberVerifiedErrorMsg: "",
      screenWidth: this.getScreenSize(),
    };
    this.securityPinrefs = [createRef(), createRef(), createRef(), createRef()];
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("resize", this.handleResize);
    //console.log('props '+JSON.stringify(this.props.history));
    //alert('login page'+JSON.stringify(this.props.propsData.history));

    // this.props.propsData.history.push(this.props.propsData.history.location.pathname);
    // alert(this.props.propsData.history.location.pathname)
  }

  insertToken = (token, userId) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      token: token,
      user_id: userId,
    };
    axios
      .post(apiURL("firebase_notification/add"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        console.log(resultData);
        if (resultData.status === true || resultData.status === 1) {
          console.log(resultData);
          this.setState({ setMeta: resultData.result });
        }
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize() {
    this.setState({
      screenWidth: this.getScreenSize(),
    });
  }
  getScreenSize() {
    const width = window.innerWidth;

    if (width <= 320) {
      return "small";
    } else if (width >= 350 && width <= 400) {
      return "medium";
    } else if (width > 400 && width <= 600) {
      return "medium_one";
    } else if (width > 600 && width <= 950) {
      return "large_one";
    } else {
      return "large";
    }
  }
  handleCheckboxChange = (event) => {
    this.setState({ checked: event.target.checked });
    if (event.target.checked === false) {
      this.setState({
        checkBoxError: true,
        checkBoxErrorMsg: "Please Check, I agree to the Terms & Conditions",
      });
    } else {
      this.setState({
        checkBoxError: false,
        checkBoxErrorMsg: "",
      });
    }
  };
  numberLengthValidate = (e) => {
    if (e.target.value.length !== 10) {
      this.setState({
        isNumberVerified: true,
        isNumberVerifiedErrorMsg: "Mobile number must be exactly 10 digits",
      });
    } else {
      this.setState({ isNumberVerified: false, isNumberVerifiedErrorMsg: "" });
    }
  };
  onSubmit = (values) => {
    const data = {
      mobile: values.number,
      pin: values.login_pin.join(""),
    };
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    axios
      .post(apiURL("teacher"), data, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          localStorage.setItem(
            "user_detail",
            JSON.stringify(resultData.result)
          );
          localStorage.setItem("login_status", true);
          if (resultData.result.fldv_email !== "") {
            localStorage.setItem("isEmailVerified", true);
          }
          //alert(JSON.stringify(this.props.propsData));
          //this.props.propsData.history.push('/');
          const path = this.props.propsData.history.location.pathname;
          // if (path !== "/sign") {
          this.props.propsData.history.push("/");

          //   if (this.props.checkJoinNow) {
          //     // this prop send from webinar as per require
          //     this.props.checkJoinNow(true);
          //   }
          // } else {
          //   this.props.propsData.history.push("/");
          // }

          if (firebase.messaging.isSupported()) {
            const messaging = firebase.messaging();

            messaging
              .requestPermission()
              .then(() => {
                return messaging.getToken();
              })
              .then((token) => {
                if (token) {
                  console.log(token);
                  this.insertToken(token, getID());
                }
              })
              .catch((error) => {
                console.log("firebase error:", error);
              });
          } else {
            console.log("firebase snot supported");
          }
          //   } else if (resultData.status === "authrntication_failed") {
          this.setState({
            snackBarMessage: resultData.message,
            showSnackbar: true,
            severityColor: "warning",
          });
        } else {
          this.setState({
            snackBarMessage: resultData.message,
            showSnackbar: true,
            severityColor: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  pinChange = (index, setFieldValue) => (e) => {
    const { value } = e.target;
    setFieldValue(`login_pin[${index}]`, value);
    if (value === "") {
      // Move back to the previous input box
      if (index > 0) {
        this.securityPinrefs[index - 1].current.focus();
      }
    } else {
      // Move to the next input box
      if (value.length === 1 && index < 3) {
        this.securityPinrefs[index + 1].current.focus();
      }
    }
  };

  // handleClickShowPassword = (event) => {
  //   this.setState({ showPassword: !this.state.showPassword });
  // };
  handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const re = /^\d+$/;
    if (!re.test(keyValue)) {
      e.preventDefault();
    }
  };
  // handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  //   this.setState({ showPassword: !this.state.showPassword });
  // };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  render() {
    const { classes } = this.props;
    const { screenWidth } = this.state;
    const inputStyles = {
      common: {
        height: "45px",
        fontSize: "40px",
        fontWeight: "400",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "40px",

        // padding: "5px 5px",
      },
      small: {
        padding: "5px",
        fontSize: "10px",
        width: "50px",
      },
      medium: {
        padding: "2px 5px",
        fontSize: "35px",
        width: "50px",
      },
      medium_one: {
        padding: "2px 5px",
        fontSize: "35px",
        width: "50px",
      },
      large_one: {
        padding: "2px 5px",
        fontSize: "35px",
        width: "50px",
      },
      large: {
        // padding: "30px 30px",
      },
    };
    return (
      <React.Fragment>
        <Helmet>
          <title>SignIn - Existing Users</title>

          <meta charset="utf-8" />
          <link rel="icon" href="/navdisha-favicon.png" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="theme-color" content="#000000" />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
          <meta name="robots" content="SignIn - Existing Users" />
          <link rel="canonical" href={window.location.href} />

          <meta name="description" content="SignIn - Existing Users" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <Formik
          initialValues={{
            number: "",
            login_pin: ["", "", "", ""],
            //agree:"",
          }}
          validationSchema={LoginSchema}
          onSubmit={this.onSubmit}
        >
          {({ errors, handleChange, setFieldValue, touched, values }) => (
            <Form id="login" className={classes.root}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    type="text"
                    className="mt-4"
                    name="number"
                    label="Mobile No."
                    onChange={handleChange}
                    onBlur={(e) => {
                      this.numberLengthValidate(e);
                    }}
                    value={this.state.number}
                    error={
                      (errors.number && touched.number) ||
                      this.state.isNumberVerified
                    }
                    helperText={
                      errors.number && touched.number
                        ? errors.number
                        : null || this.state.isNumberVerifiedErrorMsg
                    }
                  />
                </Grid>
                {/*<TextField  type="password"className="mt-4" name="password" id='name-label' label="Password" onChange={handleChange} value={this.state.password} error={errors.password && touched.password}  helperText={errors.password && touched.password ? errors.password : null} />  */}

                <Grid item xs={12} sm={12}>
                  <Typography
                    style={{
                      marginTop: "1rem",
                      color: "gray",
                      marginBottom: "1rem",
                    }}
                  >
                    Login Pin
                  </Typography>
                  <Grid container spacing={1}>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <Grid
                        item
                        xs={3}
                        md={1}
                        lg={1}
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <TextField
                          variant="outlined"
                          type="password"
                          inputProps={{
                            maxLength: 1,
                          }}
                          name={`login_pin[${index}]`}
                          fullWidth={true}
                          onChange={this.pinChange(index, setFieldValue)}
                          inputRef={this.securityPinrefs[index]}
                          value={values.login_pin[index]}
                          onKeyPress={this.handleKeyPress}
                          className="inputPin"
                          InputProps={{
                            style: {
                              ...inputStyles.common,
                              ...(inputStyles[screenWidth] || {}),
                            },
                          }}
                          required
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                {/*W<Grid item xs={12} sm={12}>  
                      <FormControl required error={errors.agree && touched.agree || this.state.checkBoxError} component="fieldset" className="mt-4" size="small"> 
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={this.state.checked} onClick={this.handleCheckboxChange} onKeyUp={handleChange} onKeyDown={handleChange} onChange={handleChange} name="agree" />}
                            label="I agree to the Terms & Conditions"
                          />  
                        </FormGroup>
                        <FormHelperText> {errors.agree && touched.agree ? errors.agree : null || (this.state.checkBoxError)?this.state.checkBoxErrorMsg:""}</FormHelperText>
                      </FormControl>
                  </Grid>*/}

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="mt-4 text-capitalize"
                  >
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.showSnackbar === true}
          autoHideDuration={6000}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SignIn);
