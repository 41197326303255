import React, { Component } from "react";
import "../style/pastraining.scss";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Link } from "react-router-dom";
import { apiURL } from "../utils";
import axios from "axios";
import Moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
const styles = (theme) => ({
  dialogPaper: {
    // minHeight: '90vh',
    // maxHeight: '90vh',
    borderRadius: "25px",
    maxWidth: "48.75rem",
  },
});

class RecentTopics extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pastEventsList: [],
      noData: "",
    };
    this.getPastEvents = this.getPastEvents.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getPastEvents();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getPastEvents = () => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      status: 1,
      event_type: "PE",
      user_id: "",
    };
    axios
      .post(apiURL("webinar/events"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        //alert(resultData.message);
        if (resultData.status === true || resultData.status === 1) {
          this.setState({ pastEventsList: resultData.result });
        } else {
          this.setState({ noData: "Comeing Soon" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <section class="careerMain eventMain">
          <Container>
            <Row className="PastTraing position-relative d-flex homeTopic">
              <h1 className="text-center d-block w-100 mt-5">
                Recent Webinars
              </h1>
              {this.state.pastEventsList.slice(0, 2).map((arrList, index) => {
                return (
                  <Col
                    key={index}
                    col="12 col-lg-6 col-xl-4"
                    className="mx-auto custom-col-spacing"
                  >
                    <Link
                      to={
                        "/past-webinar-schedule/" +
                        arrList.fldi_id +
                        "/" +
                        arrList.fldv_title.replace(/ /g, "-").toLowerCase()
                      }
                    >
                      <Card>
                        <div className="position-relative clearfix">
                          {/* {arrList.is_bookmark === true ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginBottom: "-1.2rem",
                                  // cursor: "pointer",
                                  // background: "#ebebeb",
                                }}
                              >
                                <IconButton
                                  onClick={() =>
                                    this.handleRemoveBookMark(arrList.fldi_id)
                                  }
                                >
                                  {" "}
                                  <Bookmark
                                    style={{
                                      color: "orange",
                                      position: "absolute",
                                      marginBottom: "1.3rem",
                                      marginRight: "1rem",
                                      fontSize: "1.8rem",
                                    }}
                                  />
                                </IconButton>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginBottom: "-1.2rem",
                                  // background: "#ebebeb",
                                }}
                              >
                                <IconButton
                                  style={{
                                    color: "black",
                                  }}
                                  onClick={() =>
                                    this.handleAddBookMark(arrList.fldi_id)
                                  }
                                >
                                  {" "}
                                  <BookmarkBorder
                                    style={{
                                      position: "absolute",
                                      marginBottom: "1.3rem",
                                      marginRight: "1rem",
                                      fontSize: "1.8rem",
                                    }}
                                  />
                                </IconButton>
                              </div>
                            )} */}
                          <Link
                            to={
                              "/past-webinar-schedule/" +
                              arrList.fldi_id +
                              "/" +
                              arrList.fldv_title
                                .replace(/ /g, "-")
                                .toLowerCase()
                            }
                          >
                            <div className="SpeakerBox text-center">
                              <figure>
                                <div className="Speaker">
                                  <div className="d-block imgBox">
                                    <img
                                      src={arrList.speaker_profile_image}
                                      className="img-fluid"
                                      alt={arrList.fldv_title}
                                    />
                                  </div>
                                  <h4>{arrList.speaker_name}</h4>
                                </div>
                              </figure>

                              <table className="table">
                                {/* <thead>
                                    <tr>
                                      <th>Key</th>
                                      <th>Value</th>
                                    </tr>
                                  </thead> */}
                                <tbody>
                                  <tr
                                    className="topic-row"
                                    style={{ fontWeight: "900" }}
                                  >
                                    <th
                                      style={{ fontWeight: "900" }}
                                      className="topic-key"
                                    >
                                      Topic:
                                    </th>
                                    <td className="topic-value">
                                      {arrList.fldv_title}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Date:</th>
                                    <td>
                                      {Moment(arrList.fldv_select_date).format(
                                        "ddd, D MMM, YYYY"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Time:</th>
                                    <td>
                                      {Moment(arrList.fldt_time).format("LT")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Duration:</th>
                                    <td>{arrList.fldv_duration} hrs</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Link>
                        </div>
                      </Card>
                    </Link>
                    <div className="d-flex justify-content-center position-absolute boxFeedback">
                      <Button
                        onClick={(e) =>
                          this.handleReadMore(
                            e,
                            arrList.fldi_id,
                            arrList.fldv_title.replace(/ /g, "-").toLowerCase()
                          )
                        }
                        className="CheckButton text-center"
                      >
                        {" "}
                        Read More
                      </Button>
                    </div>
                  </Col>
                );
              })}
              <div className="clearfix"></div>
              <Col col="sm-12" className="text-center noFound mt-md-5 mt-4">
                {" "}
                <h3>{this.state.noData}</h3>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(RecentTopics);
