import React, { Component } from "react";
import Navmenu from "../Navmenu";
import Appbar from "../Appbar";
import "../../style/ArticleMain.scss";
import { Row, Col, List } from "bootstrap-4-react";
import Footer from "../Footer";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import ShareNews from "../../govt-circular-gr/ShareNews";
import axios from "axios";
import { apiURL, getID } from "../../utils";
import SanitizedHTML from "react-sanitized-html";
import Moment from "moment";
import Meta from "../../meta/Meta";
import Rating from "../Rating";
import { IconButton, Container } from "@material-ui/core";
import Comments from "./Comments";
import OtherArticle from "./OtherArticle";

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
  buttonContainer: {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

class SingleBlog extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      articleData: {},
      noData: "",
      isMobile: false,
      is_bookmark: false,
      loading: true, // Add loading state
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
    this.articleListData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.blogId !== this.props.match.params.blogId) {
      this.articleListData();
    }
  }

  articleListData = () => {
    console.log("singleblog", this.props.match.params.blogId);
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      id: this.props.match.params.blogId,
      category_id: "",
      user_id: getID(),
    };
    axios
      .post(apiURL("blog/get_all_blog?per_page=" + 1 + "&page=" + 1), body, {
        headers,
      })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const articleData = resultData.result[0];
            const is_bookmark = articleData.is_bookmark;
            this.setState({
              articleData,
              is_bookmark,
              loading: false, // Update loading state
            });
          } else {
            this.setState({
              noData: "Coming Soon",
              articleData: {},
              loading: false,
            }); // Update loading state
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false }); // Update loading state on error
      });
  };

  handleAddBookMark = (id) => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "blogs",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/addBookmark"), body, { headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.success === true || resultData.success === 1) {
          this.setState({
            is_bookmark: true,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleRemoveBookMark = (id) => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "blogs",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            is_bookmark: false,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleBack = () => {
    this.props.history.push("/blogs");
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes } = this.props;
    const { isMobile, articleData, loading } = this.state;
    console.log(this.props.location.state.category);
    return (
      <React.Fragment>
        <Meta
          tableName={"blog"}
          objectId={this.props.match.params.blogId}
          forPage={"inner_page"}
        />
        <Appbar />
        <div className="sticky-top sign-light">
          <Navmenu />
        </div>
        <section className="BannerBox articleBanner">
          <div className="sixteen-nine">
            <div className="content text-center text-white invisible">
              <h2>Past Training Schedule</h2>
              <p>PAST TRAINING SCHEDULE TO FEED YOUR BRAIN</p>
            </div>
          </div>
        </section>
        <section className="articleSingle">
          <Container maxWidth="lg">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <>
                <h1>{articleData.fldv_title}</h1>
                <div className="articleList">
                  <List inline className="">
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                    <List.Item inline></List.Item>
                  </List>
                </div>
                <Row>
                  <Col sm="12">
                    <div className="fixed-content">
                      <SanitizedHTML
                        allowedAttributes={false}
                        allowedTags={false}
                        html={articleData.fldt_description}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </section>
        <section className="articleAction">
          <Container className="mt-5" maxWidth="lg">
            <div className="actionArtSingle">
              {this.state.is_bookmark ? (
                <IconButton
                  variant="contained"
                  className="bookmark"
                  style={{ color: "orange", border: "3px solid #064e89" }}
                  onClick={() => this.handleRemoveBookMark(articleData.fldi_id)}
                >
                  <Bookmark />
                </IconButton>
              ) : (
                <IconButton
                  variant="contained"
                  className="bookmark"
                  style={{ color: "#064e89", border: "3px solid #064e89" }}
                  onClick={() => this.handleAddBookMark(articleData.fldi_id)}
                >
                  <BookmarkBorder />
                </IconButton>
              )}
              <div className="rating">
                <Rating
                  blogId={articleData.fldi_id}
                  userId={getID()}
                  rating={articleData.rating}
                  color="gold"
                />
              </div>
            </div>
            <div className="share">
              <ShareNews
                articleId={articleData.fldi_id}
                shareUrl={"https://navdisha.navneet.com/"}
                title={articleData.fldv_title}
                date={Moment(articleData.fldv_select_date).format(
                  "dddd, D MMM, YYYY"
                )}
                time={Moment(articleData.fldv_from_time).format("hh:mmA")}
              />
            </div>
          </Container>
        </section>
        {!loading && articleData.fldi_id && (
          <Comments
            blogId={articleData.fldi_id}
            category_id={articleData.category_id}
          />
        )}
        {!loading && articleData.category_id && (
          <OtherArticle
            category_id={articleData.category_id}
            id={articleData.fldi_id}
            history={this.props.history}
            category_name={this.props.location.state.category}
          />
        )}
        {!loading && articleData.fldi_id !== "" ? (
          <Container>
            <div className="articleBack">
              <Button onClick={this.handleBack}>Back</Button>
            </div>
          </Container>
        ) : (
          ""
        )}
        <Footer />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SingleBlog);
