import React, { Component } from "react";
import { Row, Container } from "bootstrap-4-react";
import Navmenu from "../components/Navmenu";
import Footer from "../components/Footer";
import "../style/Glimpses.scss";
import { withStyles } from "@material-ui/core/styles";
import Appbar from "../components/Appbar";
import Meta from "../meta/Meta";

import { ArrowDownward, ArrowDropDown, DateRange } from "@material-ui/icons";
import GovtCircular from "./GovtCircular";
import { FaLanguage } from "@react-icons/all-files/fa/FaLanguage";
import Moment from "moment";
const styles = (theme) => ({
  formControl: {
    minWidth: 200,
    marginRight: theme.spacing(2),
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "100%",
    padding: "10px 30px 10px 10px",
    borderColor: "#064e89",
    borderWidth: "2px",
    borderRadius: "4px",
    appearance: "none",
    backgroundColor: "white",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#064e89",
  },
  icon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "#064e89",
  },
  label: {
    position: "absolute",
    left: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "white",
    padding: "0 5px",
    color: "#064e89",
    fontWeight: "bold",
    transition: "0.2s",
    pointerEvents: "none",
    transformOrigin: "top left",
    "&.float": {
      top: "-10px",
      transform: "scale(0.75)",
    },
  },
  filter: {
    position: "absolute",
    textAlign: "center",
    display: "block",
    marginTop: "2rem",
    width: "100%",
    fontSize: "1rem",
  },
  filter_group_year: {
    marginRight: "1rem",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem",
      marginRight: "2rem",
      fontSize: "0.8rem",
    },
  },
  filter_group_lan: {
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
      fontSize: "0.8rem",
    },
  },
});

class MHBoard extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      language: "",
      year: "",
      uniqueYears: [],
      newsData: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;

    window.addEventListener("scroll", this.signNav);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.signNav);
  }
  handleNewData = (uniqueYears) => {
    this.setState({ uniqueYears: uniqueYears });
  };
  handleYearChange = (e) => {
    const year = e.target.value;
    this.setState({ year });
  };
  handleLanguageChange = (e) => {
    const language = e.target.value;
    this.setState({ language });
  };

  render() {
    const { classes } = this.props;
    const { language, year, uniqueYears } = this.state;
    const labelLanClass = `${classes.label} ${language ? "float" : ""}`;
    const labelYearClass = `${classes.label} ${year ? "float" : ""}`;

    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />

        <div>
          <Appbar />
          <div className="sticky-top sign-light">
            <Navmenu />
          </div>
          <section className="BannerBox circularBanner">
            <div className="sixteen-nine">
              <div className="content text-center text-white d-none">
                <h2>Articles </h2>
                <p>Update</p>
              </div>
            </div>
          </section>

          <section className="mainCircularSection">
            <Container>
              <div className="filter">
                <div className="yearFilter">
                  <div className="formControl">
                    <label htmlFor="language-select" className={labelYearClass}>
                      <DateRange className="yearSvg" />
                      <span>Year</span>
                    </label>
                    <select
                      id="language-select"
                      value={year}
                      onChange={this.handleYearChange}
                      className="select"
                      onFocus={(e) => e.target.classList.add("focused")}
                      onBlur={(e) => e.target.classList.remove("focused")}
                    >
                      <option value="">All</option>
                      {uniqueYears.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <ArrowDropDown className={classes.icon} />
                  </div>
                </div>
                <div className="languageFilter d-flex justify-content-center">
                  <div className="formControl">
                    <label htmlFor="language-select" className={labelLanClass}>
                      <FaLanguage className="langSvg" />
                      <span>Language</span>
                    </label>
                    <select
                      id="language-select"
                      value={language}
                      onChange={this.handleLanguageChange}
                      className="select"
                      onFocus={(e) => e.target.classList.add("focused")}
                      onBlur={(e) => e.target.classList.remove("focused")}
                    >
                      <option value="">All</option>
                      <option value="English">English</option>
                      <option value="Marathi">Marathi</option>
                    </select>
                    <ArrowDropDown className="icon" />
                  </div>
                </div>
              </div>

              <GovtCircular
                board={"Maharashtra Board"}
                language={language}
                year={year}
                history={this.props.history}
                onDataUpdate={this.handleNewData}
              />
            </Container>
          </section>

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MHBoard);
