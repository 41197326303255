import React, { Component } from "react";
import { Row, Col, Card } from "bootstrap-4-react";
import "../../style/OtherArticle.scss";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { apiURL, getID, isLogin } from "../../utils";
import Moment from "moment";
import { Button, IconButton } from "@material-ui/core";
import { BookmarkBorder, Bookmark } from "@material-ui/icons";
import Meta from "../../meta/Meta";
import Sign from "../../login/Sign";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Container, Grid } from "@material-ui/core";
const styles = (theme) => ({
  arrowContainer: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
    cursor: "pointer",
  },
  leftArrow: {
    left: 0,
  },
  rightArrow: {
    right: 0,
  },
  arrow: {
    fontSize: "2rem",
  },
  articlesWrapper: {
    position: "relative",
    padding: "0 1rem",
  },
});

class OtherArticle extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      videoUrl: "",
      open: false,
      isOpen: false,
      articleData: [],
      categoryID: null,
      noData: "",
      selectedTab: 0,
      showComponent: false,
      id: "",
      title: "",
      loading: true,
      currentPage: 0,
      comments: [],
      isMobile: false,
    };
    this.openModal = this.openModal.bind(this);
    this.articleListData = this.articleListData.bind(this);
  }

  clickCategory = (e, id) => {
    this.setState(
      {
        categoryID: id,
        selectedTab: id,
      },
      () => {
        this.articleListData();
      }
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
    window.addEventListener("scroll", this.signNav);
    this.articleListData();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.category_id !== this.props.category_id ||
      prevProps.id !== this.props.id
    ) {
      this.articleListData();
    }
  }
  articleListData = () => {
    this.setState({ loading: true });

    if (this.props.category_id === "6") {
      const headers = {
        "Content-type": "application/json",
      };
      const body = {
        id: "",
        // category_id: this.props.category_id,
        user_id: getID(),
      };
      axios
        .post(apiURL("blog/get_all_blog"), body, { headers: headers })
        .then((response) => {
          if (this._isMounted) {
            const resultData = response.data;
            if (resultData.status === true || resultData.status === 1) {
              this.setState({
                articleData: resultData.result,
                noData: "",
                loading: false,
              });
            } else {
              this.setState({
                noData: "Coming Soon",
                articleData: [],
                loading: false,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const headers = {
        "Content-type": "application/json",
      };
      const body = {
        id: "",
        category_id: this.props.category_id,
        // user_id:getID()
      };
      axios
        .post(apiURL("article/get_article_all"), body, { headers: headers })
        .then((response) => {
          if (this._isMounted) {
            const resultData = response.data;
            if (resultData.status === true || resultData.status === 1) {
              this.setState({
                articleData: resultData.result,
                noData: "",
                loading: false,
              });
            } else {
              this.setState({
                noData: "Coming Soon",
                articleData: [],
                loading: false,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClickOpen = (e, videoUrl) => {
    this.setState({ open: true, videoUrl: videoUrl });
  };

  handleClose = () => {
    this.setState({ open: false, videoUrl: "" });
  };

  openModal() {
    this.setState({ isOpen: false });
  }

  checkJoinNow = () => {
    this.setState({ showComponent: false });
  };

  redirectArticle = (e, id, title, category) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      if (this.props.category_id === "6") {
        this.props.history.push(
          "/blog/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
          { category }
        );
      } else {
        this.props.history.push(
          "/article/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
          { category }
        );
      }
      window.scroll(0, 0);
    }
    this.setState({ id: id, title: title });
  };
  handleAddBookMark = (e, id) => {
    e.stopPropagation();
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      const headers = {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: this.props.category_id === 6 ? "blogs" : "articles",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const index = this.state.articleData.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...this.state.articleData];
              updatedEventsList[index].is_bookmark = true;
              this.setState({
                articleData: updatedEventsList,
              });
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleRemoveBookMark = (e, id) => {
    e.stopPropagation();
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: this.props.category_id === 6 ? "blogs" : "articles",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.articleData.map((event) => {
            if (event.fldi_id === id) {
              return {
                ...event,
                is_bookmark: false,
              };
            }
            return event;
          });
          this.setState({
            articleData: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handlePrev = () => {
    this.setState((prevState) => ({
      currentPage: Math.max(prevState.currentPage - 1, 0),
    }));
  };

  handleNext = () => {
    const totalPages = Math.ceil(this.state.articleData.length / 2);
    this.setState((prevState) => ({
      currentPage: Math.min(prevState.currentPage + 1, totalPages - 1),
    }));
  };

  render() {
    const { classes, id } = this.props;
    const { currentPage, articleData, isMobile } = this.state;
    const filteredData = id
      ? articleData.filter((event) => event.fldi_id !== id)
      : articleData;
    const itemsPerPage = isMobile ? 1 : 2;
    const displayData = filteredData.slice(
      currentPage * itemsPerPage,
      currentPage * itemsPerPage + itemsPerPage
    );
    const singleData = displayData.length === 1;

    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />
        <div>
          <section className="mainOther">
            <h2 className="text-center">Other {this.props.category_name}</h2>
            <Container className="container">
              <Carousel
                ref={this.carouselRef}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={currentPage}
                onChange={(index) => this.setState({ selectedItem: index })}
              >
                <Row className="otherArticle">
                  {displayData.map((arrList, index) => {
                    return (
                      <Col
                        key={index}
                        col="12 col-lg-6 col-xl-4"
                        className="mx-auto custom-col-spacing mt-4"
                      >
                        <Card
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            this.redirectArticle(
                              e,
                              arrList.fldi_id,
                              arrList.fldv_title,
                              this.props.category_name
                            )
                          }
                        >
                          <Card.Body>
                            <figure>
                              <div className="d-block mainImage">
                                <img
                                  src={arrList.fldv_image_url}
                                  className="img-fluid"
                                  alt={arrList.fldv_title}
                                />
                              </div>
                            </figure>
                            <div className="content">
                              <div className="topic">
                                <h4>{arrList.fldv_title}</h4>
                              </div>
                              <div className="date">
                                <h4>{arrList.fldv_author_name}</h4>
                              </div>
                            </div>
                          </Card.Body>
                          <div className="action">
                            <Button
                              variant="contained"
                              size="small"
                              className="readmore"
                              onClick={(e) =>
                                this.redirectArticle(
                                  e,
                                  arrList.fldi_id,
                                  arrList.fldv_title
                                )
                              }
                            >
                              Read More
                            </Button>
                            {arrList.is_bookmark ? (
                              <IconButton
                                style={{ color: "orange" }}
                                className="bookmark"
                                onClick={(e) =>
                                  this.handleRemoveBookMark(e, arrList.fldi_id)
                                }
                              >
                                <Bookmark />
                              </IconButton>
                            ) : (
                              <IconButton
                                className="bookmark"
                                onClick={(e) =>
                                  this.handleAddBookMark(e, arrList.fldi_id)
                                }
                              >
                                <BookmarkBorder />
                              </IconButton>
                            )}
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Carousel>
              <IconButton
                onClick={this.handlePrev}
                className={singleData ? "singlePrevButton" : "prevButton"}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={this.handleNext}
                className={singleData ? "singleNextButton" : "nextButton"}
              >
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OtherArticle);
