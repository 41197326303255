import React, { Component } from "react";
import { Navbar, Nav, Dropdown, Collapse } from "bootstrap-4-react";
import "../style/Navmenu.scss";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../utils";
import { spawnSync } from "child_process";
import { Tooltip } from "@material-ui/core";

class Navmenu extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      showTexts: false,
      setMenus: [],
    };
    this.getMenus = this.getMenus.bind(this);
  }

  showText = () => {
    if (window.scrollY <= 700) {
      this.setState({
        showTexts: true,
      });
    } else {
      this.setState({
        showTexts: false,
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", this.showText);
    this.getMenus();
  }

  getMenus = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      id: "",
    };
    axios
      .get(apiURL("menu/get_all_menu_test"), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({ setMenus: resultData.result });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*getIndex(menuName) {
      return this.state.setMenus.findIndex(obj => obj.fldv_name === menuName);
    }*/

  render() {
    const { arrMenus } = this.state.setMenus;
    return (
      <React.Fragment>
        <Navbar
          className={
            this.state.showTexts
              ? "navbar-light NavBox  showText"
              : "sticky-top navbar-light NavBox"
          }
          expand="lg"
        >
          <Navbar.Toggler target="#navbarSupportedContent" />
          <Collapse navbar id="navbarSupportedContent">
            <Navbar.Nav mx="auto" className="NavbarBox">
              {this.state.setMenus.map((arrMenu, index) => {
                return (
                  <span key={index}>
                    {arrMenu.arrChilds.length > 0 ? (
                      <Nav.Item dropdown>
                        <Nav.Link className="nav-link  linkBox" dropdownToggle>
                          {arrMenu.fldv_name}
                          {/* <span> {arrMenu.fldv_sub_title}</span> */}
                        </Nav.Link>

                        <Dropdown.Menu>
                          {arrMenu.arrChilds.map((arrChildMenu, childIndex) => {
                            return (
                              <span key={childIndex}>
                                <NavLink
                                  activeClassName="active_class"
                                  exact
                                  className="nav-link"
                                  to={arrChildMenu.fldv_to_path}
                                >
                                  {arrChildMenu.fldv_name}
                                </NavLink>
                                <Dropdown.Divider />
                              </span>
                            );
                          })}
                        </Dropdown.Menu>
                      </Nav.Item>
                    ) : (
                      <Nav.Item>
                        <NavLink
                          activeClassName="active_class"
                          exact
                          className="nav-link linkBox"
                          to={arrMenu.fldv_to_path}
                        >
                          {arrMenu.fldv_name}
                          <br></br>
                          {/* <span> {arrMenu.fldv_sub_title} </span> */}
                        </NavLink>
                      </Nav.Item>
                    )}
                  </span>
                );
              })}
            </Navbar.Nav>
          </Collapse>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Navmenu;
