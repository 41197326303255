import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {
  Grid,
  Snackbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import {
  apiURL,
  getContact,
  getEmail,
  getLastName,
  getName,
  isLogin,
} from "../utils";
import axios from "axios";

const formSchema = yup.object().shape({
  name: yup.string().required("This field is required."),
  contact: yup.string().required("This field is required."),
  email: yup.string().email().required("This field is required."),
  topic: yup.string().required("This field is required."),
  message: yup.string().required("This field is required."),
});

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  asterisk: {
    color: "red",
  },
});

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnMessage: "",
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      reload: false,
      contact_contactError: false,
      contact_contactErrorMsg: "",
    };
  }

  onSubmitContactUsForm = (values, { resetForm }) => {
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    axios
      .post(apiURL("contact_us_enquiry/add"), values, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            snackBarMessage:
              "Thank you for your message! We will contact you soon",
            showSnackbar: true,
            severityColor: "success",
          });
        } else {
          this.setState({
            snackBarMessage: "Server error! Please try after some time",
            showSnackbar: true,
            severityColor: "error",
          });
        }
        resetForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  contactNumberValidate = (e) => {
    const re = /^[0-9\b]+$/;
    if (!re.test(e.target.value)) {
      this.setState({
        contact_contactError: true,
        contact_contactErrorMsg: "This field accepts only digits.",
      });
    } else {
      this.setState({
        contact_contactError: false,
        contact_contactErrorMsg: "",
      });
    }
  };
  handleFocus = () => {
    if (!isLogin()) {
      this.props.history.push("/sign");
    }
  };
  contactNumberLengthValidate = (e) => {
    const re = /^[0-9\b]+$/;
    if (!re.test(e.target.value) || e.target.value.length !== 10) {
      this.setState({
        contact_contactError: true,
        contact_contactErrorMsg: "Contact number must be 10 digits.",
      });
    } else {
      this.setState({
        contact_contactError: false,
        contact_contactErrorMsg: "",
      });
    }
  };

  render() {
    const { classes, topic, userData } = this.props;

    const initialValues = {
      name:
        getName() !== "" && getLastName() !== ""
          ? getName() + " " + getLastName()
          : "",
      email: getEmail(),
      contact: getContact(),
      topic: topic !== null || topic !== "" ? topic : "",
      message: "",
    };
    return (
      <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values, actions) => {
            if (!this.state.contact_contactError) {
              this.onSubmitContactUsForm(values, actions);
            }
          }}
        >
          {({ values, errors, handleChange, touched }) => (
            <Form
              id="contact-us-form"
              className="contactUsForm"
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="filled-basic"
                    autoComplete="off"
                    variant="outlined"
                    type="text"
                    className="mt-2"
                    name="name"
                    fullWidth
                    label="Full Name"
                    value={values.name}
                    error={errors.name && touched.name}
                    onChange={handleChange}
                    onFocus={this.handleFocus}
                    style={{ fontSize: "0.9rem", background: "#f6f1f1" }}
                    required
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                    }}
                  />
                  <FormHelperText
                    error
                    style={{ fontSize: "0.8rem", color: "red" }}
                  >
                    {errors.name && touched.name ? errors.name : null}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    className="mt-2"
                    name="email"
                    fullWidth
                    label="Email"
                    value={values.email}
                    error={errors.email && touched.email}
                    onChange={handleChange}
                    onFocus={this.handleFocus}
                    required
                    style={{ fontSize: "0.9rem", background: "#f6f1f1" }}
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                    }}
                  />
                  <FormHelperText
                    error
                    style={{ fontSize: "0.8rem", color: "red" }}
                  >
                    {errors.email && touched.email ? errors.email : null}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="filled-basic"
                    autoComplete="off"
                    variant="outlined"
                    type="text"
                    className="mt-2"
                    name="contact"
                    label="Contact Number"
                    fullWidth
                    required
                    value={values.contact}
                    error={
                      (errors.contact && touched.contact) ||
                      this.state.contact_contactError
                    }
                    onChange={handleChange}
                    onFocus={this.handleFocus}
                    onInput={(e) => this.contactNumberValidate(e)}
                    onBlur={(e) => this.contactNumberLengthValidate(e)}
                    inputProps={{ maxLength: 10 }}
                    style={{ fontSize: "0.9rem", background: "#f6f1f1" }}
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                    }}
                  />
                  <FormHelperText
                    error
                    style={{ fontSize: "0.8rem", color: "red" }}
                  >
                    {errors.contact && touched.contact
                      ? errors.contact
                      : this.state.contact_contactErrorMsg}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className="mt-2"
                    style={{ fontSize: "0.9rem", background: "#f6f1f1" }}
                    fullWidth
                    required
                    onFocus={this.handleFocus}
                  >
                    <InputLabel
                      classes={{
                        asterisk: classes.asterisk,
                      }}
                    >
                      Topic
                    </InputLabel>
                    <Select
                      name="topic"
                      label="Topic"
                      value={values.topic}
                      error={errors.topic && touched.topic}
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value="Trainings">Trainings</MenuItem>
                      <MenuItem value="Articles">Articles</MenuItem>
                      <MenuItem value="Govt Circulars/GR">
                        Govt Circulars/GR
                      </MenuItem>
                      <MenuItem value="Tr for Teacher">Tr for Teacher</MenuItem>
                      <MenuItem value="Contribute">Contribute</MenuItem>
                      <MenuItem value="About Website">About Website</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText
                    error
                    style={{ fontSize: "0.8rem", color: "red" }}
                  >
                    {errors.topic && touched.topic ? errors.topic : null}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="mt-2"
                    autoComplete="off"
                    variant="outlined"
                    label="Message"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    error={errors.message && touched.message}
                    multiline
                    fullWidth
                    required
                    onFocus={this.handleFocus}
                    style={{ fontSize: "0.9rem", background: "#f6f1f1" }}
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                    }}
                  />
                  <FormHelperText style={{ fontSize: "0.8rem", color: "red" }}>
                    {errors.message && touched.message ? errors.message : null}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    className="CheckButton text-center mt-3 mx-auto px-4"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Snackbar
          open={this.state.showSnackbar === true}
          autoHideDuration={6000}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);
