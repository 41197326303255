import React, { Component } from "react";
import { Row, Col, Card } from "bootstrap-4-react";
import { IconButton, Button, Container } from "@material-ui/core";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import Meta from "../meta/Meta";
import "../style/WhatsNew.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import NewsDescriptionDialog from "../components/Article/NewsDescriptionDialog";
import Moment from "moment";
class NewsHome extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      per: window.innerWidth <= 1000 ? 1 : 2,
      newsData: [],
      categoryID: null,
      noData: "",
      selectedTab: 0,
      loading: true,
      selectedItem: 0,
      isMobile: false,
      newsList: [],
      open: false,
      title: "",
      description: "",
    };
    this.newsListData = this.newsListData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
    this.newsListData();
  }
  componentDidUpdate(prevProps, prevState) {}
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleClickOpen = (e, title, description, id) => {
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      this.props.history.push("/news");
    }
  };
  handleClose = () => {
    //this.setState({ open: false, videoUrl:'' })
    this.setState({
      open: false,
      // videoUrl: "",
    });
  };
  newsListData = () => {
    const headers = {
      "Content-type": "application/json",
    };

    axios
      .get(apiURL("article/get_data?category_id=7"), {
        headers: headers,
      })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const national = resultData.result.National || [];
            const maharashtra = resultData.result.Maharashtra || [];
            const newsList = [...national, ...maharashtra];
            this.setState({
              newsList,
              loading: false,
            });
          } else {
            this.setState({ noData: "No data available", loading: false });
          }
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  handlePrev = () => {
    this.setState((prevState) => ({
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  handleNext = () => {
    const { selectedItem, per } = this.state;
    const totalPages = Math.ceil(this.state.newsList.length / per);
    this.setState((prevState) => ({
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };
  render() {
    const { id } = this.props;
    const { newsList, selectedItem, isMobile } = this.state;

    const displayData = isMobile
      ? newsList.slice(selectedItem, selectedItem + 1)
      : newsList.slice(selectedItem * 2, selectedItem * 2 + 2);
    const singleData = displayData.length === 1;
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />

        {this.state.newsList.length !== 0 && (
          <div>
            {/* <section className="circularSection"> */}
            {/* <Container> */}
            <section className="articleGrHome">
              <Container maxWidth="lg" className="container">
                <Carousel
                  ref={this.carouselRef}
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={false}
                  showIndicators={false}
                  showStatus={false}
                  selectedItem={selectedItem}
                  onChange={(index) => this.setState({ selectedItem: index })}
                >
                  <Row className="newsHome">
                    {displayData.map((newsData, index) => {
                      return (
                        <Col
                          key={index}
                          col="12 col-lg-6 col-xl-4"
                          className="mx-auto custom-col-spacing mt-4"
                        >
                          <Card
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              this.handleClickOpen(
                                e,
                                newsData.title,
                                // newsData.fldi_id,
                                newsData.fldv_link
                              )
                            }
                          >
                            {/* <Card.Body> */}
                            <div className="mainInfo">
                              <figure>
                                <div className="d-block mainImage">
                                  <img
                                    src={newsData.image}
                                    className="img-fluid"
                                    // alt={arrArticle.fldv_title}
                                  />
                                </div>
                              </figure>
                              <div className="content">
                                <div className="topic">
                                  <h4>{newsData.title}</h4>
                                </div>
                                <div className="date">
                                  {newsData.date !== null &&
                                    newsData.date !== "" && <h4>28/06/2021</h4>}
                                </div>
                              </div>
                            </div>
                            {/* </Card.Body> */}
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Carousel>
                <IconButton
                  onClick={this.handlePrev}
                  className={singleData ? "singlePrevButton" : "prevButton"}
                >
                  <ArrowLeftIcon />
                </IconButton>
                <IconButton
                  onClick={this.handleNext}
                  className={singleData ? "singleNextButton" : "nextButton"}
                >
                  <ArrowRightIcon />
                </IconButton>
              </Container>
            </section>
            {/* </Container>
          </section> */}
            <NewsDescriptionDialog
              title={this.state.title}
              dialogOpen={this.state.open}
              description={this.state.description}
              onClose={(e) => this.handleClose(e)}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default NewsHome;
