import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { apiURL, getContact, getEmail, getPin } from "../utils";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import {
  FormGroup,
  FormControlLabel,
  Snackbar,
  DialogTitle,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { withRouter } from "react-router-dom";
const ResetPinSchema = yup.object().shape({
  old_pin: yup.string().required("This field is required."),
  new_pin: yup.string().required("This field is required."),
  confirm_pin: yup
    .string()
    .required("This field is required.")
    .when("new_pin", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf(
          [yup.ref("new_pin")],
          "Both New pin and Confirm pin need to be the same"
        ),
    }),
});

const md5 = require("md5");
class ResetPin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPinError: false,
      oldPinErrorMsg: "",
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
    };
  }
  handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const re = /^\d+$/;
    if (!re.test(keyValue)) {
      e.preventDefault();
    }
  };

  checkOldPin = (e) => {
    const pin = md5(e.target.value);
    console.log(pin, getPin());
    if (pin !== getPin()) {
      this.setState({
        oldPinError: true,
        oldPinErrorMsg: "Invalid Old Login Pin",
      });
    } else {
      this.setState({ oldPinError: false, oldPinErrorMsg: "" });
    }
  };

  onSubmit = (values) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      mobile: getContact(),
      new_pin: values.new_pin,
    };
    axios
      .post(apiURL("register/reset_pin"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;

        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            snackBarMessage: resultData.message,
            showSnackbar: true,
            severityColor: "success",
            snackBarTimer: 6000,
          });
          this.props.onCloseDialog();

          localStorage.setItem(
            "user_detail",
            JSON.stringify(resultData.result)
          );
          localStorage.setItem("login_status", true);
        } else {
          this.setState({
            snackBarMessage: resultData.message,
            showSnackbar: true,
            severityColor: "error",
            snackBarTimer: 6000,
          });
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  render() {
    const { openDialog, onCloseDialog } = this.props;
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={openDialog}
          onClose={onCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="boxMain"
        >
          <DialogTitle id="max-width-dialog-title">
            <LockOpenIcon /> Reset Login Pin
          </DialogTitle>
          <DialogContent className="dialouge">
            <Formik
              initialValues={{
                old_pin: "",
                new_pin: "",
                confirm_pin: "",
              }}
              validationSchema={ResetPinSchema}
              onSubmit={(values) => {
                if (!this.state.oldPinError) {
                  //alert(JSON.stringify(values))
                  this.onSubmit(values);
                }
              }}
            >
              {({ errors, handleChange, touched }) => (
                <Form
                  style={{ display: "flex", padding: "0px 20px 20px 20px" }}
                  id="forgot-pin"
                >
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 4, inputMode: "numeric" }}
                        id="standard-basic"
                        type="password"
                        className="mt-4"
                        name="old_pin"
                        label="Enter Old 4 Digit Pin"
                        onChange={handleChange}
                        onKeyPress={this.handleKeyPress}
                        onBlur={(e) => this.checkOldPin(e)}
                        value={this.state.old_pin}
                        error={
                          (errors.old_pin && touched.old_pin) ||
                          this.state.oldPinError
                        }
                        helperText={
                          errors.old_pin && touched.old_pin
                            ? errors.old_pin
                            : null || this.state.oldPinErrorMsg
                        }
                      />

                      <TextField
                        variant="outlined"
                        fullWidth
                        id="standard-basic"
                        type="password"
                        className="mt-4"
                        name="new_pin"
                        label="Enter New 4 Digit Pin"
                        inputProps={{ maxLength: 4, inputMode: "numeric" }}
                        onChange={handleChange}
                        onKeyPress={this.handleKeyPress}
                        value={this.state.new_pin}
                        error={errors.new_pin && touched.new_pin}
                        helperText={
                          errors.new_pin && touched.new_pin
                            ? errors.new_pin
                            : null
                        }
                      />

                      <TextField
                        variant="outlined"
                        fullWidth
                        id="standard-basic"
                        type="password"
                        className="mt-4"
                        name="confirm_pin"
                        label="Confirm Pin"
                        inputProps={{ maxLength: 4, inputMode: "numeric" }}
                        onChange={handleChange}
                        onKeyPress={this.handleKeyPress}
                        value={this.state.confirm_pin}
                        error={errors.confirm_pin && touched.confirm_pin}
                        helperText={
                          errors.confirm_pin && touched.confirm_pin
                            ? errors.confirm_pin
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="mt-4 text-capitalize"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        <Snackbar
          open={this.state.showSnackbar === true}
          autoHideDuration={this.state.snackBarTimer}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default withRouter(ResetPin);
